import { useCallback, useEffect, useRef, useState } from "react";
import arrow from "Assets/Images/arrow-gray.svg";
import MenuDrop from "./Components/MenuDrop";
import './MultiFilters.scss';
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";


const MultiFilter = ({image, options = [], disableSort = false, mainClassName = '', onSelected=()=>{},
 blockchainFilterActive = false,categoryFilterActive = true,
 communityLevelFilter=false,
 communityHomeCatFilter=false,
 communityCategory=[],
  className = '' }) => {
    const [store, dispatch] = useStore(false);
    // eslint-disable-next-line no-empty-pattern
    const {} = store;
    const { triggerAPI } = useHttp();

    const popupRef = useRef();
    const [show, setShow] = useState(false);     
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);       
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    
    const [selectedBlockchainIds, setSelectedBlockchainIds] = useState([]);       
    const [blockchainList, setBlockchainList] = useState(null);
    const [communityLevelList, setCommunityLevelList] = useState(null);
    const [selectedCommunityLevelListIds, setSelectedCommunityLevelListIds] = useState([]); 
    const [selectedCommunityCatListIds, setSelectedCommunityCatListIds] = useState([]);       


           
    const [isBlockchainFilterOpen, setIsBlockchainFilterOpen] = useState(false);

    const toggleCategoryOpen = () => {
        setIsCategoryOpen(!isCategoryOpen);
    };
    const toggleBlockchainFilterOpen = () => {
        setIsBlockchainFilterOpen(!isBlockchainFilterOpen);
    };

    // useEffect(() => {
    //     if (!filterData?.category && !isFetchingFilterData)
    //     {
    //         setIsFetchingFilterData(true);
    //         triggerAPI({
    //             url: `category/getfilterdata`, method: 'get'
    //         }, ((response)=>{            
    //             dispatch('setFilterData' ,response.data.filterData)
    //             setIsFetchingFilterData(false);
    //         }));
    //     }        
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [filterData?.category, isFetchingFilterData])    

    const getBlockChainsResponse = useCallback(
        (res) => {
            const list = res?.data?.rows?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map((item) => {
                return { name: item?.name, id: item?.id, value: item?.id, code: item?.code }
            });
            setBlockchainList(list);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const getBlockChains = useCallback(() => {
        dispatch("showSpinner");
        triggerAPI(
            {
                url: `community/blockchain-types/get`,
                method: "get",
            },
            getBlockChainsResponse
        );
    }, [dispatch, getBlockChainsResponse, triggerAPI]);

    useEffect(() => {
        if(blockchainFilterActive) {
            getBlockChains();
        }
        if(communityLevelFilter){
            setCommunityLevelList([{id:"basic",name:'Basic',value:"basic"},{id:"premium",name:'Premium',value:"premium"}])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockchainFilterActive,communityLevelFilter])  
 


    const blurHandler = () => {
        setShow(false);
    }

    const onMouseBlur = useCallback(() => {
        setShow(false);
    }, []);

    const onMouseFocus = useCallback(() => {
        setShow(()=>true);
    }, []);
    const onClicked = () => {
          setShow(!show);
    };
    const clearAll = () => {
        setSelectedCategoryIds([]);   
        setSelectedBlockchainIds([]);   
        setSelectedCommunityLevelListIds([]);  
        setSelectedCommunityCatListIds([])
        onSelected([],null)         
    }



    const updateSelectedCategoryIds = useCallback((e,el) => { 
        setSelectedCategoryIds(e);  
        onSelected(e,el)         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const updateSelectedBlockchainIds = useCallback((e,el) => { 
        setSelectedBlockchainIds(e);  
        onSelected(e,el, 'blockchains')         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    const updateSelectedComLevelIds = useCallback((e,el) => { 
        setSelectedCommunityLevelListIds(e);  
        onSelected(e,el, 'comLevels')         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    const updateSelectedComCatIds = useCallback((e,el) => { 
        setSelectedCommunityCatListIds(e);  
        onSelected(e,el, 'comHomeCat')         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    
    // if (!activeMenuIcons.filter.categoryIds)
    // {
    //     activeMenuIcons.filter.categoryIds = [];
    // }                   
    return (
        <div
            className={`filter-select ${show ? 'open-popup' : 'close-popup'} ${className}`}
            onMouseOverCapture={onMouseFocus}
            onMouseLeave={onMouseBlur}
            onBlur={blurHandler}
            tabIndex={-1}
            ref={popupRef}
        >
            <>
                <div className={'user-avatar-box'}
                    onMouseOverCapture={onMouseFocus}
                    onMouseLeave={onMouseBlur}
                    onClick={onClicked}
                >
                    {<div className={`filter-icon cursor-pointer`}>
                        <img className={'imghgt'} src={image} alt="icon"/>
                    </div>}
                </div>

                <div className="filter-selection" style={{ display:  'block' }}>
                    <ul className="drop-down-items item-first-level w-full">
                        <li className="flex flex-col false">
                            <div className="link" onClick={clearAll}>Clear all</div>
                        </li>
                        {categoryFilterActive && <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleCategoryOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Categories</div>
                                </div>                                      
                                <div className={`item-arrow ${isCategoryOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isCategoryOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={options} selected={[...selectedCategoryIds]} onClick={updateSelectedCategoryIds}/>
                                </ul>                         
                            }
                        </li>}

                        {blockchainFilterActive && <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleBlockchainFilterOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Blockchains</div>
                                </div>                                      
                                <div className={`item-arrow ${isBlockchainFilterOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isBlockchainFilterOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={blockchainList} selected={[...selectedBlockchainIds]} onClick={updateSelectedBlockchainIds}/>
                                </ul>                         
                            }
                        </li>}

                 
                        {communityHomeCatFilter && <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleBlockchainFilterOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Home Category</div>
                                </div>                                      
                                <div className={`item-arrow ${isBlockchainFilterOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isBlockchainFilterOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={communityCategory} selected={[...selectedCommunityCatListIds]} onClick={updateSelectedComCatIds}/>
                                </ul>                         
                            }
                        </li>}
                        {communityLevelFilter && <li className="flex flex-col false">
                            <div className={`flex w-full items-center justify-between gap-6 drop-down-items item-first-level`} onClick={toggleBlockchainFilterOpen}>
                                <div className="flex gap-4 items-center">                            
                                    <div className="item-name" >Levels</div>
                                </div>                                      
                                <div className={`item-arrow ${isBlockchainFilterOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>
                            </div>
                            {(isBlockchainFilterOpen) &&
                                <ul className="drop-down-items item-first-level w-full">
                                    <MenuDrop data={communityLevelList} selected={[...selectedCommunityLevelListIds]} onClick={updateSelectedComLevelIds}/>
                                </ul>                         
                            }
                        </li>}

                    </ul>                    
                </div>
            </>
        </div>
    )
}
 export default MultiFilter;