/* eslint-disable react-hooks/exhaustive-deps */
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import closeIcon from "Assets/Icons/close.svg";
import { useNavigate } from "react-router-dom";
import User from "App/Components/UI/User";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import Table from "App/Components/UI/Table";
import Button from "App/Components/UI/Button";
import "./GetCommunity.scss";
import { useDebounce } from "App/hooks/use-debounce";
import InfiniteLoader from "App/Components/InfiniteLoader";

const GetCommunity = (props) => {
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [formValidState, setFormValidState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [communitiesLists, setCommunitiesLists] = useState(null);
  const [totalCommunitiesLists, setTotalCommunitiesLists] = useState(0);
  const [communitiesPage, setCommunitiesPage] = useState(1);
  const formGroup = {
    name: {
      value: undefined,
      isRequired: true,
    },
  };
  const [formValues, setFormValues] = useState(getInitial(formGroup));
  const formCommunityGroup = {
    community: {
      value: undefined,
      isRequired: true,
    },
  };
  const [formValidSelectedCommunityState, setFormValidSelectedCommunityState] =
    useState({});
  const [formSelectedCommunityValues, setFormSelectedCommunityValues] =
    useState(getInitial(formCommunityGroup)); // user form
  const [touchedSelectedUser, setTouchedSelectedUser] = useState(false);
  const [isValidSelectedUser, setIsValidSelectedUser] = useState(false);
  useEffect(() => {
    if (touchedSelectedUser) {
      const newValues = markAsTouched(formSelectedCommunityValues);
      setFormSelectedCommunityValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchedSelectedUser]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formValidSelectedCommunityState,
      formGroup: formCommunityGroup,
      values: formSelectedCommunityValues,
    });
    setIsValidSelectedUser(valid);
  }, [
    formSelectedCommunityValues,
    formValidSelectedCommunityState,
    formCommunityGroup,
  ]);
  const collectCommunityErrors = useCallback((error) => {
    setFormValidSelectedCommunityState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const navigate = useNavigate();
  const searchValue = useDebounce(formValues?.name, 600);
  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formValidState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formValidState, formGroup]);
  const collectErrors = useCallback((error) => {
    setFormValidState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const inputOnChange = (e) => {
    setFormValues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };
  const getCommunityListsResult = useCallback(
    (res,page) => {
      dispatch("hideSpinner");
      const { list,pageMeta } = res.data;
      setTotalCommunitiesLists(pageMeta?.totalItems)
      
      setCommunitiesLists((pre)=>page===1?list:[...pre,...list]);
    },
    [dispatch]
  );
  const getCommunitiesList = (page) => {
    if(!page){
      dispatch("showSpinner");
    }
    triggerAPI(
      {
        url: `community/get`,
        data: {
          page: page||1,
          items: 25,
          search: `${formValues?.name}`,
          filter: {
            sortBy: "createdAt",
            orderBy: "DESC",
          },
        },
        method: "post",
      },
      (res)=>getCommunityListsResult(res,(page||1))
    );
  };
  const saveHandler = useCallback(() => {
    if (isValid) {
      dispatch("showSpinner");
      getCommunitiesList();
    } else {
      setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues, isValid, triggerAPI]);
  useEffect(() => {
    if (searchValue || searchValue === "") {
      saveHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const gotToBack = () => {
    if (props?.onClosePopup) {
      props?.onClosePopup();
    } else {
      navigate(-1);
    }
  };
  const addCommunityHandler = (community) => {
    setFormSelectedCommunityValues((prev) => {
      return {
        ...prev,
        community,
      };
    });
  };
  const addCollectionToCommunityApiHandlerResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      gotToBack();
      const { data } = res;
        if(data?.length) {
          let creatorData = data?.map(item => {
            let blockChains = item?.blockchain?.filter(item => item)
            let collectionFilePath = item?.collectionFilePath256?.filter(item => item)
            return  {
                blockChains: blockChains,
                coverImage: item?.bannerPicture,
                profileImage: item?.displayPicture256,
                collectionList: collectionFilePath,
                name: item?.name,
                id: item?.id,
                type: 'community'
              }
          })
          dispatch('setScreenShotData', creatorData)
        }
      props?.onRefetchList && props?.onRefetchList();
    },
    [dispatch]
  );
  const addCollectionToCommunityApiHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/move-collection`,
        data:{
          newCommunityId: formSelectedCommunityValues?.community?.id,
          collection_id: props?.collectionId
      },
        method: "post",
      },
      addCollectionToCommunityApiHandlerResult,
      (res) => {
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: res?.response?.data?.error?.message,
          },
        });
        dispatch("hideSpinner");
      }
    );
  };
  const addCollectionToCommunityHandler = () => {
    if (isValidSelectedUser) {
      addCollectionToCommunityApiHandler();
    } else {
      setTouchedSelectedUser(true);
    }
  };
  const loadMoreCommunities=()=>{
    getCommunitiesList(communitiesPage + 1);
    setCommunitiesPage((pre) => pre + 1);
    
  }
  return (
  
    <div className="get-community">
      <div className="w-full flex items-center justify-between px-[1rem]">
        <span />
        <h2 className="!text-[1rem]">Add Community</h2>
        <button onClick={gotToBack}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <div className="flex gap-4 w-full">
        <div className={`flex w-full  flex-col sm:gap-2 gap-[0.375rem]`}>
          <div>
            <InputBox
              label="Name"
              validateOnBlur
              type="text"
              value={formValues.name}
              controlName="name"
              onChange={inputOnChange}
            />
            <Validators
              type="Required"
              value={formValues.name}
              message="Required field"
              controlName="name"
              onErrors={collectErrors}
            />
          </div>
        </div>
      </div>
      <div>
        {!!communitiesLists && (
          <InfiniteLoader
          loadData={loadMoreCommunities}
          length={communitiesLists?.length}
          loaderCount={1}
          hasMore={communitiesLists?.length< totalCommunitiesLists}
          loader=""
        >
          <Table>
            <thead className="text-left">
              <tr>
                <th className="left-0">
                  <TableHeader label="Community" />
                </th>
              </tr>
            </thead>
            <tbody>
            
              {communitiesLists?.map((usr) => (
                <tr
                  key={usr.id}
                  className={`${
                    formSelectedCommunityValues?.community?.id === usr?.id
                      ? "active"
                      : "in-active"
                  }`}
                  onClick={() => addCommunityHandler(usr)}
                >
                  <td className="left-0">
                    <User
                      label={usr?.name}
                      src={usr.displayPicture256}
                      className="user-table"
                    />
                  </td>
                </tr>
              ))}
              
            </tbody>
          </Table>
          </InfiniteLoader>
        )}
        <Validators
          type="Required"
          value={formSelectedCommunityValues?.community}
          message="Select the community"
          controlName="community"
          onErrors={collectCommunityErrors}
        />
        {!!(communitiesLists && !communitiesLists?.length) && (
          <div className="text-center no-rows pt-4">
            <p>No data</p>
          </div>
        )}
        {communitiesLists?.length && (
          <div className="flex flex-col gap-4 buttons_container">
            <div className="flex justify-center gap-4 pt-4">
              <Button
                label="Cancel"
                onClick={gotToBack}
                className="transaction-cancel"
              />
              <Button
                className={`transaction-ok ${
                  !formSelectedCommunityValues?.community ? "disable" : ""
                }`}
                label={"Assign"}
                onClick={addCollectionToCommunityHandler}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetCommunity;
