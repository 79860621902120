import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import "./CreateUser.scss";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";

const CreateUser = React.memo((props) => {
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        username: {
            value: undefined,
            isRequired: true
        },
        email: {
            value: undefined,
            isRequired: true
        },
        isCurator: {
            value: false,
            isRequired: false
        },
        allowedDisplayCount: {
            value: false,
            isRequired: false
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const { triggerAPI: createUserApi } = useHttp();
    const dispatch = useStore(false)[1];
    const onClose = ({ mode = false }) => {
        props.triggerClose(mode)

    }

    const apiResult = (res) => {
        const { message, data } = res;
        dispatch('setScreenShotData', [{
            blockChains: data?.blockchain,
            coverImage: data?.bannerPicUrl,
            profileImage: data.profilePicUrl,
            name: data?.username,
            id: data?.id,
            type: 'user'
        }])
        console.log("message",message)
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        onClose({ mode: true });
    }
    const createUserHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            if (props.row?.id) {
                dispatch('showSpinner');
                delete formValues.email
                delete formValues.id
                createUserApi({
                    url: `admin/user/update/${props?.row?.id}`, data: formValues, method: 'patch'
                }, apiResult);
            }

            else {
                delete formValues.allowedDisplayCount
                dispatch('showSpinner');
                createUserApi({
                    url: 'admin/create/user', data: formValues, method: 'post'
                }, apiResult);

            }
        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    useEffect(() => {
        if (props.row?.id) {
            const result = extractFormFieldKeys({ ...formGroup, id: '' }, props.row);
            setFormvalues(result)
        }
        else {
            setFormvalues(formValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.row])
    const toggleSwitch = (id, checked) => {
        inputOnChange({
            isCurator: checked
        });
    }
    return (
        <div>
            <div className="w-full flex flex-col gap-4">
                <div className="flex gap-4 pt-6 flex-wrap justify-between">
                    <div className="user-form">
                        <InputBox label="Name" validateOnBlur type="text" controlName="username" onChange={inputOnChange} value={formValues.username} />
                        <Validators type="Required" value={formValues.username} message="Required field" controlName="username" onErrors={collectErrors} />
                    </div>
                    <div className="user-form">
                        <InputBox label="Email Address" validateOnBlur type="text" value={formValues.email} controlName="email" onChange={inputOnChange} disabled={`${props.row?.id ? "true" : ''}`} />
                        {/* <Validators type="Required" value={formValues.email} message="Required field" controlName="email" onErrors={collectErrors} /> */}
                        <Validators type="Email" value={formValues.email} message="Invalid Email" controlName="email" onErrors={collectErrors} />
                    </div>
                 
                </div>
                {props.row?.id && <div className="user-form">
                        <InputBox label="Anywall Display Count" validateOnBlur type="text" value={formValues.allowedDisplayCount} controlName="allowedDisplayCount" onChange={inputOnChange}   />
                        {/* <Validators type="Required" value={formValues.email} message="Required field" controlName="email" onErrors={collectErrors} /> */}
                        <Validators type="minValue" value={formValues?.allowedDisplayCount} minValue={0} message="Min value is 0" controlName="allowedDisplayCount" onErrors={collectErrors} />
                    </div>
                    }
                {<div className="flex user-form items-center  gap-2">
                        <div className="form-label">Curator</div>
                        <ToggleSwitch className="gallery-switch" check={formValues?.isCurator} row={{}} onChange={toggleSwitch} />
                    </div>}
            </div>

            <div className="flex transaction-button justify-center pt-12">
                <Button label="Cancel" onClick={onClose} className='transaction-cancel' />
                <Button className='transaction-ok' label={`${props?.row?.id ? 'Update' : 'Create'}`} onClick={createUserHandler} />
            </div>
        </div>
    )
})
export default CreateUser;