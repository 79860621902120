import { useCallback, useEffect } from 'react';
import './CollectorScreenShot.scss';
import { getBlockchainIcon } from 'App/helpers/blockchain';
import { Html2Canvas } from 'App/helpers/html2canvas';
import { getBLOBUrl, idName } from 'App/helpers/utilities';
import useThumnScreenshot from 'App/hooks/use-CreatorScreenshot';
import FormatField from 'App/Components/UI/FormatField';

const CollectorScreenShot = ({
    blockChains = null, coverImage = '', profileImage = '', name = '', id, type, 
    setScreenshotTaken = () => {}, onCaptureProgress = () => {}, onProgressComplete = () => {}, onCaptureError = () => {}
}) => {
    const { saveScreenShot } = useThumnScreenshot();
    const blockChainsImageList = blockChains;
    
    const screenShotResult = (blob) => {
        console.log(getBLOBUrl(blob));
        saveScreenShot({type, data: {[idName[type]]: id, thumbnailPictureFile: blob}, callBack: (res) => {
                if(!!res) {
                    setScreenshotTaken(true);
                    onProgressComplete(0.20)
                } else {
                    onProgressComplete(0.20)
                    onCaptureError(1)
                    setScreenshotTaken(true);
                }
                
            }
    });   
      }
    const generateScreenshot = useCallback(() => {
        const input  = document.getElementById(`collector-card-screenshot`);
        Html2Canvas({input, backgroundColor: '#191D23', callback: screenShotResult, cloneId: 'collector-card-screenshot', onCaptureProgress, onCaptureError, onProgressComplete})  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if(type)
            generateScreenshot()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])
    return (
        <div className='collector-card-screenshot' id='collector-card-screenshot'>

            <div className='head-section flex flex-col gap-[4rem] justify-between items-center w-full' style={
                {
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                                url(${coverImage}) center/cover no-repeat`, objectFit: 'cover'
                }}>
                <div className='cover-image'>
                    <div className='profile-image'>
                        <img src={profileImage} alt='' />
                    </div>
                    <div className='creator-name px-4'>
                        <p><FormatField value={name} type={'sub'} maxWidth={20} /> </p>
                    </div>
                    <div className='blockchain-section'>
                        <div className='blockchain-container'>
                            {blockChainsImageList?.map((blockchainIcon, index) => <div className='blockchain-image relative' key={blockchainIcon}>
                                    <img src={getBlockchainIcon(blockchainIcon)} alt=' ' className='w-full h-full' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CollectorScreenShot;