import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import Image from "App/Components/UI/Image";
import { useCallback, useEffect, useMemo, useState } from "react";
import tableSort from "App/helpers/tableSort";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import "./Reports.scss";
import { LIST_LIMIT } from "App/constants";
import useReportHelper from "../../APIHandler";

const paginationConfig = {
  perPage: LIST_LIMIT,
};

const CommunityReports = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const dispatch = useStore(false)[1];
  const [search, setSearch] = useState("");
  const [initial, setInitial] = useState(true);
  const { getCommunityReportDataAPI } = useReportHelper();
  const communityMenu=useMemo(()=>[
    {
      label: "Community",
      action: "communityId",
      sort: true,
      sortKey: "communityName",
      multiSelect: true,
      selected: true,
      type: "displayedFrames",
    },
    {
      label: "User",
      action: "user",
      sort: true,
      sortKey: "userName",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Created Date",
      action: "createdDate",
      sort: true,
      sortKey: "createdAt",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Reason",
      action: "reason",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Comment",
      action: "comment",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
  ],[]);

  const [sort, setSort] = useState({ direction: "DESC", sortKey: "createdAt" });
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "";
      const direction = tableSort(dir);
      setSort({ direction, sortKey });
    },
    [sort]
  );
  const [list, setList] = useState([]);

  const onPageChangeHandler = (page) => {
    setPage(page);
    const root = document.getElementById("content");
    root.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const searchHandler = (e) => {
    setSearch(e);
  };

  const getReportLists = useCallback(() => {
    dispatch("showSpinner");
    const filter = {
      sortBy: sort.sortKey,
      orderBy: sort.direction,
      page: page,
      items: paginationConfig.perPage,
    };
    let data = {
      ...filter,
    };
    if (search) {
      data = { ...data, search };
    }
    getCommunityReportDataAPI(data, (res) => {
      dispatch("hideSpinner");
      const { list, pageMeta } = res.data;
      setList(list);
      setTotalPage(pageMeta.totalItems);
    });
  }, [
    dispatch,
    getCommunityReportDataAPI,
    page,
    search,
    sort.direction,
    sort.sortKey,
  ]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getReportLists();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, search]);

  useEffect(() => {
    if (!initial) {
      getReportLists();
    }
    setInitial(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch("setPageTitle", "Reports");
  }, [dispatch]);

//   const creatorLink = (slug) => {
//     return `${process.env.REACT_APP_USER_SITE_URL}/@${slug}/overview`;
//   };

  return (
    <>
      <div className="communities-report">
        <div className="flex justify-between items-center mb-4">
          <div className="search-filter">
            <SearchFilter
              position={{ right: 150, top: 10 }}
              onSearch={searchHandler}
              hideData={{filter:true}}
            //   setActionMenu={setCommunityMenu}
            //   actionMenu={communityMenu}
            //   limit={4}
            />
          </div>
        </div>

        <div>
          <Table>
            <thead className="text-left">
              <tr>
                {/* <th>
                  <TableHeader
                    label="Community"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="name"
                  />
                </th> */}

                {communityMenu
                  ?.filter((el) => el?.selected)
                  ?.map((val, i) => (
                    <th key={`${val?.action}${i + 1}`}>
                      <TableHeader
                        label={val?.label}
                        sort={sort}
                        onSort={(key) => val?.sort && onSortHandler(key)}
                        sortKey={val?.sortKey ? val?.sortKey : ""}
                      />
                    </th>
                  ))}
                {/* <th>
                  <TableHeader
                    label="Status"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="status"
                  />
                </th> */}
              </tr>
            </thead>
            <tbody className="gallery-body">
              {list?.map((value) => {
                let imagePreview = value?.community?.displayPicture256;
                return (
                  <tr data-galleryid={value?.id} key={value.id}>
                    {!!communityMenu?.find((val) => val?.label === "Community")
                      ?.selected && (
                      <td className="flex gap-3 items-center ">
                        <div className="wall-image-gallery">
                          <Image
                            src={imagePreview ? imagePreview : ""}
                            className="gallery-image"
                            loaderClass="nft-drop-loader"
                            style={{ maxWidth: "4rem", Height: "4rem" }}
                          />
                        </div>
                        <span className="name-span">{value?.community?.name}</span>
                        {/* <div className="anchor-wrapper">
                          <a
                            onClick={(e) => e.stopPropagation()}
                            className="link-img-wrapper"
                            target="_blank"
                            rel="noreferrer"
                            href={creatorLink(value?.slug)}
                          >
                            <img src={MenuLink} alt="" />
                          </a>
                        </div> */}
                      </td>
                    )}
                    {!!communityMenu?.find((val) => val?.label === "User")
                      ?.selected && (
                      <td>{value?.user?.username || value?.user?.name}</td>
                    )}
                    {!!communityMenu?.find(
                      (val) => val?.label === "Created Date"
                    )?.selected && (
                      <td>{new Date(value?.createdAt)?.toLocaleString()}</td>
                    )}

                    {!!communityMenu?.find((val) => val?.label === "Reason")
                      ?.selected && <td  title={value?.reason}>{value?.reason ?? ""}</td>}

                    {!!communityMenu?.find((val) => val?.label === "Comment")
                      ?.selected && <td  title={value.comment}>{value?.comment}</td>}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!list?.length && (
            <div className="text-center no-rows pt-4">
              <p>No reports found!</p>
            </div>
          )}
          <div className="mt-6">
            <Pagination
              showPageInput={true}
              itemsPerPage={paginationConfig.perPage}
              total={totalPage}
              rows={list?.length}
              onPageChange={onPageChangeHandler}
              selected={page}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityReports;
