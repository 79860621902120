
import DatePickerImg from 'Assets/Images/DatePicker.svg';
import "./SearchFilter.scss";
import { Fragment, useEffect, useState } from 'react';
import DatePickerFilter from '../DatePickerFilter';
import ButtonAction from '../ButtonAction';
import StatusFilter from '../StatusFilter';
import WallColor from '../WallColor';
import WallStyle from '../WallStyle';
import SelectFilter from '../SelectFilter';
import RangeFilter from '../RangeFilter';
import WallType from '../WallType';
import SearchBar from './Components/SearchBar';
const d = new Date();
let currentYear = d.getFullYear();
const yearList = Array.from(Array(50), (_, x) => {return {name: currentYear - x, id: currentYear - x, value: currentYear - x}});
const SearchFilter = (props) => {
    const [value, setValue] = useState({ mode: false })
    const [component, setComponent] = useState([])
    const [dates, setDates] = useState([])
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [selectedValue, setSelectedValueStatus] = useState([]);
    const [filterComponent, setFilterComponent] = useState('')
    const [wallColor, setWallColor] = useState([]);
    const [wallStyle, setWallStyle] = useState([]);
    const [wallType, setWallType] = useState([]);
    const [applyFilter,setApplyFilter] = useState([]);

    useEffect(()=>{
        props?.actionMenu?.length&& setApplyFilter(props?.actionMenu)
    },[props?.actionMenu])


    const onCloseHandler = (e) => {
        setValue({ mode: false })
    }

    const closeHandler = (e) => {
        if (e.action === 'cancel') {
            props.onFilterData({
                ...props.data,
                from: ''
                , to: ''
            })
            setDates([])
        }
        else if (e.action === 'reset') {
            props.onFilterData({
                ...props.data,
                paymentStatus: ''
            })
            setPaymentStatus([])
        }
        else if (e.action === 'color') {
            props.onFilterData({
                ...props.data,
                color: ''
            })
            setWallColor([])
        }
        else if (e.action === 'style') {
            props.onFilterData({
                ...props.data,
                style: ''
            })
            setWallStyle([])
        }
        else if (e.action === 'wallType') {
            props.onFilterData({
                ...props.data,
                types: ''
            })
            setWallType([])
        }
        else if (e.action === 'resetSelect') {
            props.onFilterData({
                ...props.data,
                year: ''
            })
        }
        else if (e.action === 'resetRange') {
            props.onFilterData({
                ...props.data,
                nftValue: ''
            })
        }
        onCloseHandler()
        const activeTab = applyFilter?.map((val) => {
            if (val.action === component.action) {
                return { ...val, selected: false }
            }
            return val
        })
        props.setActionMenu && props.setActionMenu(activeTab)
    }

    const onFilterData = (date) => {
        props.onFilterData({ ...props.data, ...date })
        onCloseHandler()
        changeSelectedMenu(component.action, true)
    }

    const menuActionHandler = (e) => {
        setValue({ mode: true })
        setComponent(e)
        changeSelectedMenu(e.action, false)
    }

    const changeSelectedMenu = (action, highlight) => {
        let type = ''
        let flag=false;
        let active=0;
        applyFilter.forEach((el) => {
            if (el.action === action) {
                type = el.type
                flag=el?.multiSelect;
            }
            if(el?.selected) active++
        })
        const activeTab = applyFilter?.map((val) => {
            
            if (val.action === action) {
                const value = { ...val, selected: val?.popup ? (highlight || val.selected)&&(props?.limit?active<props?.limit:true) ? true : false :props?.limit?val.selected?false:(active<props?.limit)?!val.selected:val.selected:!val.selected  }
                setSelectedValueStatus(value)
                return value
            }
            else if (type === val.type) {
                return { ...val, selected: flag?val?.selected:false }
            }
            else {
                return val
            }

        })
        props.setActionMenu && !props?.apply&& props.setActionMenu(activeTab)
        props.setActionMenu && props?.apply && setApplyFilter(activeTab)
    }

    const onApplyHandler=()=>{
        props.setActionMenu(applyFilter)
        onCloseHandler()
    }

    useEffect(() => {
        switch (component?.action) {
            case 'dateRange':
                setFilterComponent(<DatePickerFilter show={value} onClose={closeHandler} onCloseHandler={onCloseHandler} onFilterData={onFilterData} data={props.data} setDates={setDates} dates={dates} />)
                break;
            case 'nameAsc':
                props.onFilterData({
                    ...props.data,
                    "sortBy": "name",
                    "orderBy": "ASC"
                })
                break;
            case 'nameDesc':
                props.onFilterData({
                    ...props.data,
                    "sortBy": "name",
                    "orderBy": "DESC"
                })
                break;
            case 'priceAsc':
                props.onFilterData({
                    ...props.data,
                    "sortBy": "price",
                    "orderBy": "ASC"
                })
                break;
            case 'priceDesc':
                props.onFilterData({
                    ...props.data,
                    "sortBy": "price",
                    "orderBy": "DESC"
                })
                break;
            case 'Pending':
                if (selectedValue.selected) {
                    props.onFilterData({
                        ...props.data,
                        verificationStatusId: [...props.data?.verificationStatusId, 1]
                    })
                }
                else {
                    props.onFilterData({
                        ...props.data,
                        verificationStatusId: props.data?.verificationStatusId.filter((val) => val !== 1)
                    })
                }
                break;
            case 'Accepted':
                if (selectedValue.selected) {
                    props.onFilterData({
                        ...props.data,
                        verificationStatusId: [...props.data.verificationStatusId, 2]
                    })
                }
                else {
                    props.onFilterData({
                        ...props.data,
                        verificationStatusId: props.data?.verificationStatusId.filter((val) => val !== 2)
                    })
                }
                break;
            case 'Rejected':
                if (selectedValue.selected) {
                    props.onFilterData({
                        ...props.data,
                        verificationStatusId: [...props.data.verificationStatusId, 3]
                    })
                }
                else {
                    props.onFilterData({
                        ...props.data,
                        verificationStatusId: props.data?.verificationStatusId.filter((val) => val !== 3)
                    })
                }

                break;

            case 'displayedFrames':
                if (selectedValue.selected) {
                    props.onFilterData({ ...props.data, "showOnHomePageDisplay": true })
                }
                else {
                    props.onFilterData()
                }
                break;
            case 'status':
                setFilterComponent(<StatusFilter show={value} onClose={closeHandler} onCloseHandler={onCloseHandler} onFilterData={onFilterData} data={props.data} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} />)
                break;
            case 'wallColor':
                setFilterComponent(<WallColor show={value} onClose={closeHandler} onCloseHandler={onCloseHandler} onFilterData={onFilterData} data={props.data} wallColor={wallColor} setWallColor={setWallColor} />)
                break;
            case 'wallStyle':
                setFilterComponent(<WallStyle show={value} onClose={closeHandler} onCloseHandler={onCloseHandler} onFilterData={onFilterData} data={props.data} wallStyle={wallStyle} setWallStyle={setWallStyle} />)
                break;
                case 'wallType':
                    setFilterComponent(<WallType show={value} onClose={closeHandler} onCloseHandler={onCloseHandler} onFilterData={onFilterData} data={props.data} wallType={wallType} setWallType={setWallType} />)
                    break;
            case 'year':
                setFilterComponent(<SelectFilter
                        showApplyButton={false} 
                        show={value} 
                        onCloseHandler={onCloseHandler} 
                        onClose={closeHandler}
                        onChange={onYearChange} 
                        onApply={onFilterData}
                        list={yearList} 
                        value={props?.data?.year} 
                        keyName={'year'} 
                        selectName={'Year'} 
                    />)
                break;
            case 'nftSupply':
                setFilterComponent(<RangeFilter 
                        show={value} 
                        onCloseHandler={onCloseHandler} 
                        onClose={closeHandler}
                        onChange={onRangeChange} 
                        onApply={onFilterData}
                        list={yearList} 
                        value={props?.data?.nftValue} 
                        keyName={'nftValue'} 
                        rangeName={'NFT Supply'} 
                    />)
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component, value])
    const onYearChange = (e) => {
        props.onFilterData({
            ...props.data,
            year: e.year
        })
    }
    const onRangeChange = (e) => {
        props.onFilterData({
            ...props.data,
            nftValue: e
        })
    }

    return (
        <Fragment>
            <div className={`flex gap-3 ${props?.className}`}>
                {!props?.noSearch&&<SearchBar onSearch={props?.onSearch}/>}
               {!props?.hideData?.filter&&<div className='flex search search-date'>
                    <ButtonAction layout="settings" menuAlign="right"  onApplyHandler={onApplyHandler} className='search-filter' apply={props?.apply} menu={applyFilter} position={props.position} src={DatePickerImg} onAction={menuActionHandler} />
                </div>}
            </div>
            {filterComponent}
        </Fragment>
    )
}
export default SearchFilter;