import useScreenSize from "App/hooks/use-screen-size";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./CollectionCard.scss";
import userBanner from 'Assets/Icons/new-logo-text.svg'
import { useStore } from "App/hooks-store/store";
import Logo from "../Logo";
import { getBlockchainIcon } from "App/helpers/blockchain";
import BlockChainIcon from "../BlockchainIcon";
import useSlicedImageList from "App/hooks/use-slice-image-list";
import { formatNumberWithThousand } from "App/helpers/utilities";
import defaultImage from 'Assets/Icons/logo-square.svg'
import Button from "../UI/Button";

const CollectionCard = (props) => {
  const screenSize = useScreenSize()?.width;
  const [disableHover,setDisableHover] = useState(false)
  const { leftMenuCollapse, userProfileImage, userProfile } = useStore(false)[0];
  const targetRef = useRef(null);
  const updateHeight = () => {
    const root = document.documentElement;
   
    if (targetRef.current&&targetRef?.current?.offsetWidth!==0) {
        root?.style.setProperty(
          "--collection-card-height",
          `${targetRef.current.offsetWidth}px`
        );
        root?.style.setProperty("--discover-collection-card-height",`${targetRef.current.offsetWidth}px`)
    }
  };
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1500);
  }, [props?.row, screenSize, leftMenuCollapse]);

  const showBannerHandler = (resolution = '1x') => {
    let bannerImg1x = props?.row?.community 
      ? props?.row?.filePath512 || defaultImage
      : props?.row?.showcase || userBanner;
  
    let bannerImg2x = props?.row?.community 
      ? props?.row?.filePath1024 || defaultImage
      : props?.row?.showcase || userBanner;
  
    return resolution === '2x' ? bannerImg2x : bannerImg1x;
  }
  const BannerHandler = ()=>{
    let bannerImg= props?.row?.community ? props?.row?.filePath256 : props?.row?.showcase || false;
    return bannerImg
  }
useLayoutEffect(() => {
  const image = new Image();
  image.src = props?.row?.collage
}, [props?.row?.collage]);

const onEditClickHandler=(e)=>{
  e && e.stopPropagation()
  props?.onEditClick && props.onEditClick(e,props?.row?.id, props?.collectionType, props?.row?.nmkrUid)
}

const onDeleteClickHandler=(e)=>{
  e && e.stopPropagation()
  props?.onDeleteClick && props.onDeleteClick(e,props?.row?.id)
}
const logo = useMemo(() => Logo(), []);

const imagesListMaxCount = useMemo(()=>{
 // return isMobile?4:5
 return 5
},[])

const imagesToList =useSlicedImageList(props?.row?.curatorProfile,imagesListMaxCount);

const handleMouseEnterEditDelete = () => {
// if(isMobile) setDisableHover(true);  // Disable flip
};

const handleMouseLeaveEditDelete = () => {
  setDisableHover(false); // Enable flip back
};

const moveCollectionHandler=(data,e)=>{
e.stopPropagation();
props.moveHandler(props?.row)
}
  return (
    <div
      ref={targetRef}
      className={`image-item collection-sq-card flex flex-col cursor-pointer relative ${props?.mainClass}`}
      data-id={props?.row?.id}
      key={props?.row?.id}
      onClick={(e) => props?.cardClick && props?.cardClick(e)}
      data-communityid={props?.row?.communityId}
      data-policy={`${
        props?.row?.collectionCollectionId ?? props?.row?.collectionId
      }`}
    >
      <div
        className={`flip-card ${
          (props?.showData?.hideFlip || disableHover) && "hide-flip"
        }`}
      >
        <div className="flip-card-inner">
          <div
            className={`image-container relative flip-card-front ${
              !BannerHandler() && "no-banner"
            }
            ${(props?.edit || props?.delete) && "edit-delete-on"}`}
            style={{
              backgroundImage: `image-set(url(${showBannerHandler(
                "1x"
              )}) 1x, url(${showBannerHandler("2x")}) 2x)`,
            }}
          >
            {/* {!!props?.row?.curatorProfile?.length &&<div className={`short-info-label ${(props?.delete || props?.edit)?"sm:flex hidden":"flex"}`}>
            <ImagesListing
              imagesToList={imagesToList}
              imagesMaxCount={imagesListMaxCount}
              />
              Curated 
            </div>} */}
            {/* {(props?.delete || props?.edit) && <div className={`edit-delete-wrap`} onMouseEnter={handleMouseEnterEditDelete}
                onMouseLeave={handleMouseLeaveEditDelete}>
                  <EditDelete isDeletable={props?.delete} isEditable={props?.edit} 
                  editHandlerFn={onEditClickHandler} deleteHandlerFn={onDeleteClickHandler} isDeleteBox isEditBox/>
                </div>} */}
          </div>
          <div
            className={`image-container relative flip-card-back ${
              !BannerHandler() && "no-banner"
            } 
            ${(props?.edit || props?.delete) && "edit-delete-on"}`}
            style={{
              backgroundImage: `url(${
                props?.row?.collage ?? showBannerHandler()
              })`,
            }}
          >
            {/* {(props?.delete || props?.edit) && <div className={`edit-delete-wrap`} onMouseEnter={handleMouseEnterEditDelete}
                onMouseLeave={handleMouseLeaveEditDelete}>
                  <EditDelete isDeletable={props?.delete} isEditable={props?.edit} 
                  editHandlerFn={onEditClickHandler} deleteHandlerFn={onDeleteClickHandler} isDeleteBox isEditBox/>
                </div>
            } */}
            {!!props?.moveHandler && (
              <div className={`edit-delete-wrap`}>
                <Button
                  label="Move"
                  onClick={moveCollectionHandler}
                  className={`action-btn`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card-details flex justify-between items-center px-[0.375rem] py-[0.625rem]">
        <div
          className={`${props?.className} flex gap-3 justify-start items-start `}
        >
          {props?.showData?.profileIcon && (
            <div className="community-pic">
              <img
                src={
                  props?.isMedia
                    ? userProfileImage || logo
                    : props?.row?.community?.displayPicture256 || logo
                }
                alt=""
              />
            </div>
          )}
          <div className="flex flex-col items-start justify-start mt-[-0.25rem] gap-[0.25rem]">
            {props?.showData?.collectionName && (
              <div
                className="collection-name text-left"
                title={props?.row?.name}
              >
                {props?.row?.name ?? ""}
              </div>
            )}
            {!!props?.showData?.communityName && (
              <div
                className="creator-name"
                title={
                  props?.isMedia
                    ? userProfile?.username
                    : props?.row?.community?.name
                }
              >
                {props?.isMedia
                  ? userProfile?.username
                  : props?.row?.community?.name ?? "Unknown"}
              </div>
            )}
            {!!props?.showData?.blockchainIconAndPieceCount && (
              <div className="flex items-center sm:gap-6 gap-[0.75rem]">
                {props?.row?.blockchainType?.code ? (
                  <img
                    className={`w-[1.2rem] rounded-full`}
                    alt="Img"
                    src={getBlockchainIcon(props?.row?.blockchainType?.code)}
                  />
                ) : (
                  <BlockChainIcon
                    classes={`w-[1.2rem]`}
                    loaderH={"1.05rem"}
                    policyId={props?.row?.policy}
                  />
                )}
                <div className={`nft-price flex`}>
                  <span
                    className={`dot-separator`}
                  >{`${formatNumberWithThousand(
                    props?.row?.assetsCount || props?.row?.assetCount
                  )} piece${
                    props?.row?.assetsCount !== 1 ||
                    props?.row?.assetCount !== 1
                      ? "s"
                      : ""
                  }`}</span>
                </div>
              </div>
            )}
            {props?.showData?.tag && (
              <div className={`content-start count-holdr tag-list`}>
                <div
                  className={`nft-gallery-head tag-name flex gap-x-3 gap-y-2 flex-wrap items-center  ml-auto`}
                >
                  {props?.row?.collectionTags?.slice(0, 3)?.map((val) => (
                    <span key={val} className="text-xs">
                      #{val}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {props?.showData?.count && (
          <div className={`content-start count-holdr`}>
            <div
              className={`nft-gallery-head nft-gallery-count flex gap-1 items-center  ml-auto`}
            >
              <span>
                {(props?.row?.assetsCount ?? props?.row?.assetCount) || 0}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
