import "./AssetCard.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import defaultImage from "Assets/Icons/logo-square.svg";
import { getHtmlAR } from "App/helpers/aspectRatioCalculation";
import BoxFavoriteIcon from "App/Components/IconsComponents/FavoriteIcons/boxFavoriteIcon";
import BoxEyeVisibleIcon from "App/Components/IconsComponents/EyeVisibleIcons/boxEyeVisibleIcon";
import BoxEyeInVisibleIcon from "App/Components/IconsComponents/EyeInvisibleIcons/boxEyeInvisibleIcon";
import SkeletonLoader from "App/Components/UI/SkeletonLoader";
import SmallInfoSection from "App/Components/SmallInfoSection";
import ImageCard from "../ImageCard";
import Button from "App/Components/UI/Button";
import UpdateMarketplacePopup from "../UpdateMarketplacePopup";
const AssetCard = ({
  asset = null,
  showData = {},
  draggable = false,
  draggableClassName,
  disableFavorite,
  disableActions,
  favoriteHandler = () => {},
  hideHandler = () => {},
  selectedAsset = false,
  onAssetClick = () => {},
  onEditAsset = () => {},
  getImageAspectRatio = () => {},
  showFrame = false,
  showCaption = false,
  showPricing = false,
  isGallery = false,
  isOwnCreator = false,
  onDeleteAsset = () => {},
  isMedia = false,
  setAsThumbnailHandler,
  actionsWithText,
  updateMarketplaceLinkHandler
}) => {
  const [src, setSrc] = useState(null);
  const targetRef = useRef();
  const pieceRef = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const [showUpdateMarketplacePopup, setShowUpdateMarketplacePopup] = useState(false);
  const ImageClickHandler = () => {
    if (!showLoader) onAssetClick(asset, isGallery);
  };

  useEffect(() => {
    let metaData = asset?.onchain_metadata,
      imageSrc =
        asset?.nftcdnimage1024 ||
        asset?.nftcdnimage ||
        asset?.nftcdnimage512 ||
        defaultImage;
    if (metaData) {
      let mediaType =
        metaData?.mediaType ?? metaData?.image_properties?.mime_type;
      if (mediaType && !mediaType?.includes("image")) imageSrc = defaultImage;
    }
    setSrc(imageSrc);
  }, [asset]);
  const handleInfoClick = (e) => {
    e.stopPropagation();
    onAssetClick(asset);
  };
  const handleAspectRatio = useCallback(
    (e) => {
      let aspectRatio = e;
      if (Array?.isArray(asset?.files) && !!asset?.files?.length) {
        aspectRatio = getHtmlAR(asset?.files) ? 1 : e;
      }
      getImageAspectRatio(Number(aspectRatio?.toFixed(2)));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [asset?.files]
  );

  const handleDeleteAsset = useCallback(
    (e) => {
      e.stopPropagation();
      onDeleteAsset(asset);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [asset]
  );

  const handleEditAsset = useCallback(
    (e) => {
      e.stopPropagation();
      onEditAsset(asset);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [asset]
  );
  const favActionHandler = (e,event) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
  
  
    
    if (
      !(
        (disableFavorite && !asset?.isFavoriteCollectionAsset) ||
        disableActions
      )
    ) {
      setShowLoader(true);
      favoriteHandler((e && e.stopPropagation?e:event), asset, (res) => {
        setShowLoader(false);
      });
    }
  };
  const hideActionHandler = (e,event) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (!disableActions) {
      setShowLoader(true);
      hideHandler((e && e.stopPropagation?e:event), asset, () => {
        setShowLoader(false);
      });
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(pieceRef.current);
    };
  }, []);
  const setAsThumbnailActionHandler = (_,e) => {
    e.stopPropagation();
    if (!disableActions) {
      setShowLoader(true);
      pieceRef.current = setTimeout(() => {
        setShowLoader(false);
      }, 500);
      setAsThumbnailHandler(src);
    }
  };
  const updateMarketplaceHandler=(_,e)=>{
    e.stopPropagation()
    if(!disableActions){
        setShowUpdateMarketplacePopup(true)
    }

}
const onCloseUpdateMarketplaceHandler = () => {
  setShowUpdateMarketplacePopup(false)
}
const onUpdateMarketplaceLinkHandler = (data,callback) => {
  setShowLoader(true)
  updateMarketplaceLinkHandler({ ...asset, ...data }, (res) => {
      setShowLoader(false)
      callback(true)
  })
}
  const cardImageSection = (
    <div
      draggable={!showLoader && draggable}
      className={`asset-img ${
        selectedAsset && isGallery && "active-asset"
      } ${draggableClassName}`}
      ref={targetRef}
      onClick={ImageClickHandler}
    >
      <ImageCard
        skeltonClass={``}
        overlayClass={""}
        data-index={asset?.asset}
        imageRatioCalculated={handleAspectRatio}
        className={`selected-nft-cards-collection inner-nft`}
        nftSrc={src}
        showLoader={showLoader}
        nftImageSizes={asset}
      />
      {!!((showData?.favorite || showData?.hide||showData?.updateMarketplaceLink) && !showLoader) && (
        <div className={`action-icons gap-2 items-center flex-wrap`}>
          {!!(showData?.favorite && !asset?.isHidedCollectionAsset) &&
            (actionsWithText ? (
              <Button
                label={
                  asset?.isFavoriteCollectionAsset
                    ? "Remove from collage"
                    : "Add to collage"
                }
                onClick={favActionHandler}
                disabled={
                  (disableFavorite && !asset?.isFavoriteCollectionAsset) ||
                  disableActions
                }
                className={`text-btn icon-list ${
                  ((disableFavorite &&
                    !asset?.isFavoriteCollectionAsset) ||
                    disableActions) &&
                  "!cursor-not-allowed"
                }`}
              />
            ) : (
              <BoxFavoriteIcon
                className={`icon-list  ${
                  asset?.isFavoriteCollectionAsset && "active"
                } ${asset?.isFavoriteCollectionAsset && "activeIcon"} 
                        ${
                          ((disableFavorite &&
                            !asset?.isFavoriteCollectionAsset) ||
                            disableActions) &&
                          "cursor-not-allowed"
                        }`}
                onClick={favActionHandler}
              />
            ))}
          {!!(showData?.hide && !asset?.isFavoriteCollectionAsset) ? (
            actionsWithText ? (
              <Button
                label={asset?.isHidedCollectionAsset ? "Unhide" : "Hide"}
                onClick={hideActionHandler}
                disabled={disableActions}
                className={`text-btn icon-list ${
                  disableActions && "!cursor-not-allowed"
                }`}
              />
            ) : asset?.isHidedCollectionAsset ? (
              <BoxEyeVisibleIcon
                className={`icon-list  ${
                  asset?.isHidedCollectionAsset && "active"
                } ${disableActions && "cursor-not-allowed"}`}
                onClick={hideActionHandler}
              />
            ) : (
              <BoxEyeInVisibleIcon
                className={`icon-list ${
                  asset?.isHidedCollectionAsset && "active"
                } ${disableActions && "cursor-not-allowed"}`}
                onClick={hideActionHandler}
              />
            )
          ) : (
            <></>
          )}
          {!!showData?.setThumbnail && (
            <Button
              label="Set as thumbnail"
              onClick={setAsThumbnailActionHandler}
              className={`text-btn icon-list`}
            />
          )}
          {!!showData?.updateMarketplaceLink &&
            <Button
              label={"Update Marketplace Link"}
              onClick={updateMarketplaceHandler}
              className={`text-btn icon-list`}
            />
          }
        </div>
      )}
      {/* {(isMedia || isOwnCreator) && (
        <div className={`edit-delete-wrap info_icon-asset`}>
          <EditDelete
            isDeletable={true}
            isEditable={true}
            editHandlerFn={handleEditAsset}
            data-id={asset?.asset}
            data-action={"show-info"}
            deleteHandlerFn={handleDeleteAsset}
            isDeleteBox
            isEditBox
          />
        </div>
      )} */}
    </div>
  );

  return (
    <>
    <div className={`asset-card-section relative`}>
      <div>{cardImageSection}</div>

      {showCaption &&
        (!showLoader ? (
          <div className={``}>
            <div className="flex flex-col sm:gap-2 gap-[0.25rem]">
              <SmallInfoSection
                showData={{ ...showData, assetName: true }}
                data={asset}
              />
              {/* {showPricing &&
                            <div className="flex sm:gap-6 gap-[0.75rem]">
                            {(asset.marketplace?.priceDisplay || asset.marketplace?.price) &&
                                <div className="flex gap-2">
                                    {!asset.marketplace?.priceDisplay &&
                                        <img
                                            className={`w-[1.2rem]`}
                                            alt="Img"
                                            src={getBlockchainIcon(asset?.blockchainType?.code) || adaIcon}
                                        />
                                    }
                                    <span className={`nft-price character-icon-text`}>
                                        {(asset.marketplace?.priceDisplay ?? formatNumberWithThousand((asset?.marketplace?.price / 1000000)))}
                                    </span>
                                </div>
                            }
                            {asset.marketplace?.priceUSD &&
                                <div className="flex gap-2">
                                    {asset.marketplace?.state === 'sold' && <span className={``}>
                                        SOLD
                                    </span>}
                                    <span className={``}>
                                        $ {asset?.marketplace.priceUSD}
                                    </span>
                                </div>
                            }
                            <div className="flex sm:gap-6 gap-[0.75rem]">
                                <div className={`${!(!asset?.marketplace?.priceDisplay &&
                                    !asset?.marketplace?.price &&
                                    asset.marketplace?.state !== 'sold' &&
                                    !asset?.marketplace?.priceUSD)&&"dot-separator"} nft-price`}>{`1/${asset?.mint_or_burn_count}`}</div>
                            </div>
                        </div>
                        } */}
            </div>
          </div>
        ) : (
          <SkeletonLoader height={15} count={1} inline={true} />
        ))}
    </div>
    {!!showUpdateMarketplacePopup && <UpdateMarketplacePopup  data={asset}
     updateMarketplaceHandler={onUpdateMarketplaceLinkHandler} 
     onClose={onCloseUpdateMarketplaceHandler} />}
    </>
  );
};

export default AssetCard;
