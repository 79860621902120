/* eslint-disable react-hooks/exhaustive-deps */
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import Table from "App/Components/UI/Table";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import "./LoyaltyTransactions.scss";
const LoyaltyTransactions = (props) => {

  const [list, setList] = useState([]);
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const { row } = props;

  const getTransactionResult = useCallback((res) => {
      dispatch('hideSpinner');
      let list = res?.data?.list
      
      setList(list)
  }, [dispatch])

  const getTransactionDetails = useCallback(() => {
      dispatch('showSpinner');

      console.log('row',row)
      triggerAPI({
          url: `admin/loyalty-user-transactions/${row?.row?.id}?page=1&items=10000`, method: 'get'
      }, getTransactionResult);
  }, [dispatch, getTransactionResult, row.id, triggerAPI])

  useEffect(() => {
      getTransactionDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    (
          <Table>
            <thead className="text-left">
              <tr>
                <th className="sticky left-0">
                  <TableHeader label="Date" />
                </th>
                <th >
                  <TableHeader label="Activity" />
                </th>
                <th >
                  <TableHeader label="Points" />
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((itm) => (
                <tr
                  key={itm.id}
                  className={`active`}
                >
                  <td>{new Date(itm?.created_at)?.toLocaleString()}</td>
                  <td>{itm?.title}</td>
                  <td>{itm?.points}</td>

                </tr>
              ))}
            </tbody>
          </Table>
        )
         
  );
};

export default LoyaltyTransactions;
