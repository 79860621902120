import { objectToFormdata } from "App/helpers/utilities";
import useHttp from "./use-http";

const useThumnScreenshot = () => {    
    const { triggerAPI } = useHttp();

    const saveScreenShot = async ({data, callBack = null, type}) => {   
        const formData = objectToFormdata(data);                                
        triggerAPI(
            {
                url: `${type}/update-thumbnail`,
                data: formData,
                method: "post",
            }, (res) => {
                callBack && callBack(res);
            }, () => {
                callBack && callBack(false);
            }
        );                    
    }   
    return {
        saveScreenShot        
    }
}
export default useThumnScreenshot