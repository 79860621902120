import CollectionCard from "../CollectionCard";
import DetailsSkeltonCard from "../DetailsSkeleton";
import InfiniteLoader from "../InfiniteLoader";
import "./CollectionsLists.scss";

const CollectionsLists = ({
  spinnerMode,
  count = 5,
  loadMore,
  totalItemsCount,
  collectionsLoaderCount = 5,
  collections,
  parentScrollContainerQuery,
  moveHandler
}) => {
  return (
  
    <div className={`flex flex-col gap-4 collection-list`}>
      {!!(spinnerMode && !collections) && (
        <DetailsSkeltonCard
          show={spinnerMode}
          height={"100%"}
          count={count}
          inline={true}
          details={"img-2-line"}
          noAbsolute
          containerClassName={`flex`}
        />
      )}
      {!!collections?.length && (
        <InfiniteLoader
          loadData={loadMore}
          length={collections?.length}
          loaderCount={collectionsLoaderCount}
          hasMore={collections?.length < totalItemsCount}
          loader="details-skeleton"
          parentScrollContainerQuery={parentScrollContainerQuery}
        >
          <div className={`grid collection-container gap-3 relative`}>
            {collections?.map((list, i) => {
              return (
                <CollectionCard
                  showData={{
                    profileIcon: true,
                    collectionName: true,
                    communityName: true,
                    count: false,
                    blockchainIconAndPieceCount: true,
                    tag: true,
                  }}
                  key={i + 1}
                  row={list}
                  moveHandler={moveHandler}
                  // cardClick={cardClick}
                  mainClass="!cursor-default"
                />
              );
            })}
          </div>
        </InfiniteLoader>
      )}
      {!!(!spinnerMode && !collections?.length) && (
        <div className="text-center no-rows pt-4">
          <p>No collections to display</p>
        </div>
      )}
    </div>
  );
};

export default CollectionsLists;
