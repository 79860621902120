import ReloadIcon from 'Assets/Icons/ReloadIcon.svg';

const ReloadCell = ({ row, onClick,isLoading }) => {
    
    const handleClick = async () => {
       if (onClick) {
        onClick({row})
        }
    };
  
    return (
      <img 
        src={ReloadIcon} 
        className={`${isLoading && `animate-spin`} ${isLoading ? 'cursor-not-allowed' : ''}`} 
        onClick={handleClick}
        alt="Refresh points"
      />
    );
  };

  export default ReloadCell;