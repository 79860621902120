import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import "./AddWallet.scss";
import { getBlockchainIcon } from "App/helpers/blockchain";
import SelectBox from "App/Components/UI/SelectBox";

const AddWallet  = React.memo((props) => {
    const [formVaildState, setFormVaildState] = useState({});
      const [blockchainTypeList, setBlockchainTypeList] = useState([]);
    
    const formGroup = {
        walletAddress: {
            value: undefined,
            isRequired: true
        },
        chain: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const onClose = ({ mode = false }) => {
        props.triggerClose(mode)

    }

      const getBlockChainTypeResponse = useCallback(
        (res) => {
         dispatch("hideSpinner");
          const list = res?.data?.rows?.sort((a,b)=>a.sortOrder>b.sortOrder?1:-1).map((item) => {
            return { name: item?.name, id: item?.id, value: item?.id, code: item?.code, icon: getBlockchainIcon(item?.code), showIcon: true }
          });
          
          setBlockchainTypeList(list);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
      );

      const getBlockChainType = useCallback(() => {
        dispatch("showSpinner");
        triggerAPI(
          {
            url: `community/blockchain-types/get`,
            method: "get",
          },
          getBlockChainTypeResponse
        );
      }, [dispatch, getBlockChainTypeResponse, triggerAPI]);

    const apiResult = (res) => {
        const { message, data } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        onClose({ mode: true });
    }
    const addWalletHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
                delete formValues.id
                dispatch('showSpinner');
                triggerAPI({
                    url: 'admin/add-wallets', data: {...formValues,userId:props.row.id}, method: 'post'
                }, apiResult);

        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    useEffect(() => {
   
        setFormvalues(formValues)
        getBlockChainType();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.row])

    return (
        <div className="user-wallet-create">
        <div className="w-full">
          <div className="flex gap-2 pt-1 column">

            <div className="sub-admin-form">
              <SelectBox
                validateOnBlur
                list={blockchainTypeList}
                placeHolder="Select"
                label={`Blockchain`}
                className="frame-form"
                controlName={"chain"}
                onChange={inputOnChange}
                currentId={formValues?.chain}
                keyName={'code'}
                tabIndex={0}
              />
              <Validators
                type="Required"
                value={formValues.chain}
                message="Required field"
                controlName="chain"
                onErrors={collectErrors}
              />
            </div>
            <div className="sub-admin-form">
              <InputBox
                label="Wallet Address"
                validateOnBlur
                type="text"
                autoComplete="new-password"
                controlName="walletAddress"
                onChange={inputOnChange}
                value={formValues.walletAddress}
              />
              <Validators
                type="Required"
                value={formValues.walletAddress}
                message="Required field"
                controlName="walletAddress"
                onErrors={collectErrors}
              />
            </div>
 
          </div>
        </div>
  
             <div className="flex transaction-button justify-center pt-12">
                 <Button label="Cancel" onClick={onClose} className='transaction-cancel' />
                 <Button className='transaction-ok' label={`Add`} onClick={addWalletHandler} />
             </div>
      </div>
    )
})
export default AddWallet ;