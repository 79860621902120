import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import SelectBox from "App/Components/UI/SelectBox";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useMemo, useState } from "react";

function CollectorCollectionSearch(props) {
  const [formVaildState, setFormVaildState] = useState({});
  const [formValues, setFormvalues] = useState({});
  const [blockchainTypes, setBlockchainTypes] = useState();
  const status = [
    {
      "id": 1,
      "name": 'All',
      "value": 'all'
    },
    {
       "id": 3,
       "name": 'Un-reviewed',
       "value": 'inactive'
    },
    {
      "id": 4,
      "name": 'Reviewed',
      "value": 'reviewed'
    },
    {
      "id": 2,
      "name": 'Active',
      "value": 'active'
    },
  ]

  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];

  const getBlockchainTypes = () => {
    return new Promise((resolve) => {
        triggerAPI({
            url: `blockchain/getall`, method: 'get'
        }, (res => {
            resolve(res.data)
        }));
    })
}

const getDropDown = () => {
  dispatch('showSpinner');
  Promise.all([
    getBlockchainTypes(),
  ]).then(res => {
      dispatch('hideSpinner');
      const [data] = res;
      setBlockchainTypes(
        data.map(i=>{
          return {
            "id": i.id,
            "name": i.name,
            "value": i.code,
          }
        }
          
        )
      );
  })
}

  const formGroup = useMemo(() => {
    let list = {
      walletAddress: {
        value: undefined,
        isRequired: true,
      },
      note: {
        value: undefined,
        isRequired: false,
      },
      blockchainType: {
        value: undefined,
        isRequired: true,
      },
      status: {
        value: undefined,
        isRequired: true,
      },
    };
    setFormvalues(getInitial(list));
    return list;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.row]);

  const onClose = ({ mode = false }) => {
    props.triggerClose(mode);
  };



  const submitHandler = () => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    if (valid) {
      dispatch("showSpinner");

      let data = {
                walletAddress: formValues.walletAddress || null,
                note: formValues.note || null,
                status:status.find(
                  (g) => g.id === formValues.status
                ).value,
                blockchain: blockchainTypes.find(
                  (g) => g.id === formValues.blockchainType
                ).value
              };

      props.submit(data);
    } else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  };

  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  useEffect(() => {
    if (props.row?.id) {
      const result = extractFormFieldKeys(
        { ...formGroup, id: "" },
        { ...props.row }
      );
      // result.blockchain = galleryTypeList.find(
      //   (g) => g.value === result.blockchainType
      // ).code; //test

      setFormvalues(result);


    } else {
      setFormvalues(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);

  useEffect(() => {
    getDropDown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div className="sub-admin-create">
      <div className="w-full">
        <div className="flex gap-2 pt-1 column">
        <div className="sub-admin-form">
            <SelectBox
              validateOnBlur
              list={blockchainTypes}
              placeHolder="Select"
              label={`Blockchain Type`}
              className="frame-form"
              controlName={"blockchainType"}
              onChange={inputOnChange}
              currentId={formValues?.blockchainType}
              tabIndex={0}
            />
            <Validators
              type="Required"
              value={formValues?.blockchainType}
              message="Required field"
              controlName="blockchainType"
              onErrors={collectErrors}
            />
          </div>  
          <div className="sub-admin-form">
            <InputBox
              label="Wallet"
              validateOnBlur
              type="text"
              controlName="walletAddress"
              onChange={inputOnChange}
              value={formValues.walletAddress}
            />
            <Validators
              type="Required"
              value={formValues.walletAddress}
              message="Required field"
              controlName="walletAddress"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <SelectBox
              validateOnBlur
              list={status}
              placeHolder="Select"
              label={`Collection Status`}
              className="frame-form"
              controlName={"status"}
              onChange={inputOnChange}
              currentId={formValues?.status}
              tabIndex={0}
            />
            <Validators
              type="Required"
              value={formValues?.status}
              message="Required field"
              controlName="status"
              onErrors={collectErrors}
            />
          </div>  
          <div className="sub-admin-form">
            <InputBox
              label="Note"
              type="text"
              controlName="note"
              onChange={inputOnChange}
              value={formValues.note}
            />
            <Validators
              value={formValues.note}
              controlName="note"
              onErrors={collectErrors}
            />
          </div>

        </div>
      </div>

      <div className="flex transaction-button justify-center pt-12">
        <Button
          label="Cancel"
          onClick={onClose}
          className="transaction-cancel"
        />
        <Button
          className="transaction-ok"
          label="Search"
          onClick={submitHandler}
        />
      </div>
    </div>
  );
}

export default CollectorCollectionSearch;
