import Button from "App/Components/UI/Button";
import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import User from "App/Components/UI/User";
import tableSort from "App/helpers/tableSort";
import React, { useCallback, useEffect, useState } from "react";
import ButtonLink from "App/Components/UI/ButtonLink";
import Status from "App/Components/UI/Status";
import ButtonAction from "App/Components/UI/ButtonAction";
import ActionButton from "Assets/Images/ActionButton.svg";
import SidePanel from "App/Components/UI/SidePanel";
import WalletAddressDetails from "./Components/WalletAddressDetails";
import Popup from "App/Components/UI/Popup";
import CreateUser from "./Components/CreateUser";
import AddWallet from "./Components/AddWallet";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import Plus from "Assets/Images/plus.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import MenuGallery from "Assets/Images/menu-gallery.svg";
import MenuDetails from "Assets/Images/menu-details.svg";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import { useNavigate } from "react-router-dom";
import "./Components/TransactionDetails/TransactionDetails.scss";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import ViewDetails from "./Components/ViewDetails";
import { LIST_LIMIT } from "App/constants";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import { getBlockchainIcon } from "App/helpers/blockchain";
import TableSelectBox from "App/Components/UI/TableSelectBox";
import ReloadCell from "App/Components/UI/ReloadCell";
import LoyaltyTransactions from "./Components/LoyaltyTransactions";
const actionMenu = [{
    label: 'View Gallery',
    action: 'viewGallery',
    src: MenuGallery
}, {
    label: 'View Details',
    action: 'viewDetails',
    src: MenuDetails
},
 {
    label: 'Edit',
    action: 'edit',
    src: MenuPen
}, 
{
    label: 'Add Wallet',
    action: 'addWallet',
    src: Plus
}, 
{
    label: 'Block',
    action: 'block',
    src: MenuLink
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]
const menuAction = [{
    label: 'View Gallery',
    action: 'viewGallery',
    src: MenuGallery
}, {
    label: 'View Details',
    action: 'viewDetails',
    src: MenuDetails
}, {
    label: 'Edit',
    action: 'edit',
    src: MenuPen
},
{
    label: 'Unblock',
    action: 'Unblock',
    src: MenuLink
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]

const paginationConfig = {
    perPage: LIST_LIMIT
}
const UserList = React.memo(() => {
    const [dateMenu, setDateMenu] = useState([{
        label: 'Date Range',
        action: 'dateRange',
        selected: false,
        popup: true
    }
    ])
    const [initial, setInitial] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const { triggerAPI } = useHttp();
    const [store, dispatch] = useStore(false);
    const { createdByUserName } = store;
    const [list, setList] = useState([]);
    const [category, setCategory] = useState([]);

    const [selectedData, setSelectedData] = useState({})
    const [showWalletAddress, setShowWalletAddress] = useState({ mode: false });
    const [showBlockPopup, setShowBlockPopup] = useState({ mode: false });
    const [showCreateUserPopup, setShowCreateUserPopup] = useState({ mode: false });
    const [showNewWalletPopup, setShowNewWalletPopup] = useState({ mode: false });

    const [showDeletePopup, setShowDeletePopup] = useState({ mode: false });
    const [showViewDetailsPopup, setShowViewDetailsPopup] = useState({ mode: false });
    const [showMenuAction, setShowMenuAction] = useState();
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ direction: '', sortKey: 'name' });
    const [selectedDate, setSelectedDate] = useState('')
    const [loadingRows, setLoadingRows] = useState({});
    const [showLoyaltyTransactionHistory, setShowLoyaltyTransactionHistory] = useState({ mode: false });

    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort]);
    const navigate = useNavigate()
    const userCreationHandler = () => {
        setSelectedData({})

        setShowCreateUserPopup({ mode: true })
    }
    const viewWalletAddressHandler = (e) => {
        setSelectedData(e.row)
        setShowWalletAddress({ mode: true })
    }
    const viewWalletTransactionHandler = (e) => {
        const { id = '' } = e.row;
        navigate(`/dashboard/user-transactions/${id}`, { state: { from: 'user' } })
    }
    const onCloseCreateUser = (data) => {
        setShowCreateUserPopup({ mode: false })
        if (data) {
            getUserList()
        }
    }
    const onCloseLoyaltyTransactionHistory = (data) => {
        setShowLoyaltyTransactionHistory({ mode: false })
    }
    const onCloseAddWallet = (data) => {
        setShowNewWalletPopup({ mode: false })
        if (data) {
            getUserList()
        }
    }

    const closeWalletPanel = () => {
        setShowWalletAddress({ mode: false })
    }
    const actionHandler = (e) => {
        setSelectedData(e.row)
        setShowMenuAction(e.action)
        if (e.action === "block" || e.action === "Unblock") {
            setShowBlockPopup({ mode: true })
        }
        else if (e.action === "delete") {
            setShowDeletePopup({ mode: true })
        }
        else if (e.action === "viewDetails") {
            setShowViewDetailsPopup({ mode: true })
        }

        else if (e.action === "viewGallery") {
            navigate(`/dashboard/gallery-management/gallery/${e.row.id}`)
        }
        else if (e.action === "edit") {
            setShowCreateUserPopup({ mode: true })
        }
        else if (e.action === "addWallet") {
            setShowNewWalletPopup({ mode: true })
        }
    }

    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        setShowBlockPopup({ mode: false })
        getUserList()
    }

    const apiDeleteResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        setShowDeletePopup({ mode: false })
        getUserList()
    }

    const apiRefreshLoyaltyResult = (res) => {
        setList(prevData => prevData.map(user => 
            user.id == res.data.userId ? { ...user, loyaltyPoints:res.data.loyaltyPoints } : user
          ));
        setLoadingRows(prev => ({ ...prev, [res.data.userId]: false }));
    }

    const onCloseBlockUser = (data) => {
        if (data) {
            blockUserHandler(data)
        }
        else {
            setShowBlockPopup({ mode: false })

        }
    }

    const onCloseDeleteUser = (data) => {
        if (data) {
            deleteUserHandler()
        }
        else {
            setShowDeletePopup({ mode: false })

        }
    }

    const blockUserHandler = (e) => {
        const data = {
            id: selectedData?.id,
        }
        dispatch('showSpinner');
        if (showMenuAction === 'block') {
            triggerAPI({
                url: 'admin/user/block', data, method: 'patch'
            }, apiResult);
        }
        else {
            triggerAPI({
                url: 'admin/user/unBlock', data, method: 'patch'
            }, apiResult);
        }

    }
    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }

    const deleteUserHandler = () => {
        const data = {
            id: selectedData.id,
        }
        dispatch('showSpinner');
        triggerAPI({
            url: 'admin/user/delete', data, method: 'delete'
        }, apiDeleteResult);
    }

    const refreshLoyaltyPoints = (usr) => {
        setLoadingRows(prev => ({ ...prev, [usr?.row?.id]: true }));
        triggerAPI({
            url: `admin/refresh-user-loyalty-point/${usr?.row?.id}`, data:{}, method: 'post'
        }, apiRefreshLoyaltyResult);
    }

    const viewUserLoyaltyPointsHandler = (row) => {
        setSelectedData(row)
        setShowLoyaltyTransactionHistory({ mode: true })
    }


    const onPageChangeHandler = (page) => {
        setPage(page)
        const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const getUserlistResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        let userList = list?.map((ele) => {
            const blockchainIcons= ele.blockchain.map(bi=>getBlockchainIcon(bi))
            const collectionsBlockchains= ele?.collectionsBlockchains ? ele?.collectionsBlockchains?.map(bi=>getBlockchainIcon(bi)) : []

            return { ...ele, blockchainIcons:blockchainIcons,collectionsBlockchains }
          });
        setList(userList)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])


    const getUserList = useCallback(() => {
        dispatch('showSpinner');
        let filter;
        if (sort.direction || selectedDate.from) {
            filter = {
                sortBy: sort.sortKey,
                orderBy: sort.direction,
                from: selectedDate.from,
                to: selectedDate.to
            }
        }
        else {
            filter = ''
        }
        let searchValue = search
        if(!!createdByUserName) {
            searchValue = createdByUserName;
        }
        triggerAPI({
            url: `admin/user/list`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: searchValue, filter
            }, method: 'post'
        }, getUserlistResult);

        if(!!createdByUserName) {
            dispatch('setCreatedByUserName', null);
        }
    }, [createdByUserName, dispatch, getUserlistResult, page, search, selectedDate.from, selectedDate.to, sort.direction, sort.sortKey, triggerAPI])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getUserList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, selectedDate])

    useEffect(() => {
        getCategoryList();
        if (!initial) {
            getUserList();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getToggleCuratorStatusUpdateResult = (res) => {
        getUserList();
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
      }
    


    const stopHandler = (e) => {
        e.stopPropagation();
      };

      const getToggleCuratorHomeStatusUpdate = (id, checked) => {
        dispatch('showSpinner');
        const data = {
            id:id,
            showOnHomePageDisplay:checked
           }
        triggerAPI({
            url: `user/display-status/update`, data, method: 'patch'
        }, getToggleCuratorStatusUpdateResult);
    }

    const getCategoryResponse = useCallback(
        (res) => {
          setCategory(res?.data);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
      );

    const getCategoryList = useCallback(() => {
        triggerAPI(
          {
            url: `admin/curator-categories`,
            method: "get",
          },
          getCategoryResponse
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch, getCategoryResponse, triggerAPI]);

    const getSelectListBoxResult = (res) => {
        getUserList();
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
      }

    const getSelectListBoxUpdate = (selected, id) => {
        dispatch('showSpinner');
        let data = {};
        if(selected['categoryId']===0){
          data = { 
            userId:id?.id,
            isCurator:false,
            curatorCategory:null
             }
          triggerAPI({
            url: `admin/user/curator-status`, data, method: 'patch'
        }, getSelectListBoxResult);
      }else{
        data = {
            userId:id?.id,
            isCurator:true,
            curatorCategory:selected['categoryId']
          }
          triggerAPI({
            url: `admin/user/curator-status`, data, method: 'patch'
        }, getSelectListBoxResult,(err) => {
            getUserList();            
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message:
                err?.response?.data?.error?.message
            },
          });
        });
      }
    }

    const selectListBox = (selected,row) => {
        getSelectListBoxUpdate(selected,row);
      }



    const toggleSwitchHomeStatus = (id, checked) => {
        getToggleCuratorHomeStatusUpdate(id, checked);
    }

    const downloadExcelFile = async (res) => {
        dispatch("hideSpinner");
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Cur8_Users_List.xlsx');  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      };

    const onExport = () => {
        dispatch("showSpinner");
        triggerAPI(
          {
            url: `admin/export-users-details`,
            method: "get",
            responseType:'blob',
            headers: {
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
          },
          downloadExcelFile,
          (err) => {
            dispatch("hideSpinner");
            dispatch("showToast", {
              toast: {
                toastMode: "error",
                message:
                  err?.response?.data?.error?.message || "Error downloading the file",
              },
            });
          }
        );
      };

      const refreshCollections = (e) => {
        const data = {}
        const userIds=[]
        list?.forEach((usr) =>{userIds.push(usr.id)})
        data["userIds"]=userIds;
        dispatch('showSpinner');
            triggerAPI({
                url: 'admin/refresh-users-collections', data, method: 'post'
            }, apiResult);

    }

    useEffect(()=>{
      if(createdByUserName) {
        getUserList();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[createdByUserName]);
    const getGenratethumnailList = useCallback(() => {
        dispatch("showSpinner");
        triggerAPI({
            url: `admin/user-list/generate-thumbnail`,
            method: "get",
          },(response) => {
            dispatch("hideSpinner");
            const { data } = response;
            if(data?.length) {
                let collectorData = data?.map(item => {
                    let blockChains = item?.blockchain?.filter(Boolean);                    
                    if (item?.profilePicUrl) {
                        return {
                            blockChains,
                            coverImage: item?.bannerPicUrl,
                            profileImage: item.profilePicUrl,
                            name: item?.username,
                            id: item?.id,
                            type: 'user'
                        };
                    }
                    return null
                }).filter(Boolean);
              dispatch('setScreenShotData', collectorData)
            }
          });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setDateMenu} actionMenu={dateMenu} onFilterData={onFilterData} />
                </div>
                <div className="flex gap-2 items-center">
                    <Button label="Refresh Collection Count" onClick={refreshCollections} />
                    <Button label="Add" onClick={userCreationHandler} />
                    <Button label="Export" onClick={onExport} />
                    <Button label="Genrate thumbnails" onClick={getGenratethumnailList}/>
                </div>
            </div>
            <div className="mb-40">
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th className="sticky left-0 bg-[#272832]">
                                <TableHeader label="User" sort={sort} sortKey="name" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Email" />
                            </th>
                            <th>
                                <TableHeader label="Registration Date" sort={sort} sortKey="createdAt" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Registration Device"/>
                            </th>
                            <th>
                                <TableHeader label="Last Login" sort={sort} sortKey="lastLoginDate" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Subscription" />
                            </th>
                            <th>
                                <TableHeader label="Subscription Expiry" sort={sort} sortKey="subscriptionExpiry" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Curator Type"  sort={sort} sortKey="isCurator" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Creator Collections" sort={sort} sortKey="collectionsCount" onSort={onSortHandler} />
                            </th>
                           
                            <th>
                                <TableHeader label="Wallets" sort={sort} sortKey="connectedWallets" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Pieces in collections" sort={sort} sortKey="collectionsAssetsCount" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Galleries (PVT/PUB)"/>
                            </th>
                            <th>
                                <TableHeader label="Categorized Collections"  sort={sort} sortKey="categorizedWalletCollectionsPercentage" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Creator Categorized" sort={sort} sortKey="categorizedWalletCreatorLinkedCollectionsPercentage" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Loyalty Points" sort={sort} sortKey="loyaltyPoints" onSort={onSortHandler}/>
                            </th>
                            <th>
                                <TableHeader label="Show on Home" />
                            </th>
                            <th>
                                <TableHeader label="Wallet Address" />
                            </th>
                            <th>
                                <TableHeader label="Transactions" />
                            </th>
                            <th>
                                <TableHeader label="Status" />
                            </th>
                            <th>
                                <TableHeader label="Actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((usr,index) => <tr key={usr.id}>
                            <td className="sticky left-0 bg-[#14141C] z-[1]">
                                <User label={usr?.username} src={usr.profilePicUrl} className="user-table" />
                            </td>
                            <td>
                                {usr.email}
                            </td>
                            
                            <td>
                            {new Date(usr.createdAt)?.toLocaleString()}
                            </td>
                            <td>
                            {usr.registrationDevice}
                            </td>
                            <td>
                            {usr.lastLoginDate && new Date(usr.lastLoginDate)?.toLocaleString()}
                            </td>
                            <td>
                                {usr.subscriptionPlan}
                            </td>
                            <td>
                                {usr.subscriptionPlan==='Basic' ? null : usr?.subscriptionExpiry && new Date(usr?.subscriptionExpiry)?.toLocaleString()}
                            </td>
                            
                      {<td>
                         
            <TableSelectBox
              list={usr?.curatorCategoryId ? [...category,{id:0,name:'Clear'}]:category}
              placeHolder="No"
              label={``}
              className="frame-form"
              onChange={selectListBox}
              keyName={"id"}
              controlName="categoryId"
              row={usr}
              currentId={usr?.curatorCategoryId}
              tabIndex={0}
            />
                        </td>}

                            <td>
                                <div className="flex gap-1">
                                {usr?.collectionsCount}
                            {/* <div className="flex w-max">
                                {parseInt(usr?.collectionsCount) >0 && <>(</>}
                            
                            {parseInt(usr?.collectionsCount) >0 && usr?.collectionsBlockchains.map(icon => (
                            <img src={icon} alt="blockchain icon" className="w-[1.4rem]" key={icon} />
                            ))}
                                {parseInt(usr?.collectionsCount) >0 && <>)</>}
                            
                            </div> */}
                         
                        </div>
                            </td>
                            <td >
                            
                            <div className="flex gap-1">
                            {usr?.connectedWallets}
                            <div className="flex w-max">
                                {parseInt(usr?.connectedWallets) >0 && <>(</>}
                            
                            {parseInt(usr?.connectedWallets) >0 && usr?.blockchainIcons.map(icon => (
                            <img src={icon} alt="blockchain icon" className="w-[1.4rem]" key={icon} />
                            ))}
                                {parseInt(usr?.connectedWallets) >0 && <>)</>}
                            
                            </div>
                         
                        </div>
                            </td>
                            <td>
                                {usr?.collectionsAssetsCount}
                            </td>
                            <td>
                                {usr?.privateGalleryCount}/{usr?.publicGalleryCount}
                            </td>
                            <td>
                            <>(</>{usr?.totalCategorizedWalletCollections}/{usr?.totalWalletCollections}<>)</> {usr?.categorizedWalletCollectionsPercentage}%
                            </td>
                            <td>
                            <>(</>{usr?.totalWalletCreatorLinkedCategorizedCollections}/{usr?.totalWalletCreatorLinkedCollections}<>)</> {usr?.categorizedWalletCreatorLinkedCollectionsPercentage}%
                            </td>
                            <td>
                                <div className="flex cursor-pointer reload-section gap-2">
                                {usr?.loyaltyPoints} 
                                {usr?.loyaltyPoints ? <ButtonLink row={usr} label="View" onClick={viewUserLoyaltyPointsHandler} /> : "" } 
                                <ReloadCell row={usr} onClick={refreshLoyaltyPoints} 
                                isLoading={loadingRows[usr.id] || false}
                                />
                                                    </div>
                            </td>
                            <td>
                                <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                                    <ToggleSwitch className="gallery-switch" check={usr?.showOnHomePageDisplay} row={usr} onChange={toggleSwitchHomeStatus} />
                                </div>
                            </td>
                            <td>
                                <ButtonLink row={usr} label="View" onClick={viewWalletAddressHandler} />
                            </td>
                            <td>
                                <ButtonLink label="View" row={usr} onClick={viewWalletTransactionHandler} />
                            </td>
                            <td>
                                <Status label={usr?.isBlocked ? 'Blocked' : 'Active'} />
                            </td>
                            <td>
                                <div className="flex justify-end items-center">
                                    <ButtonAction src={ActionButton} menu={usr.isBlocked ? menuAction : actionMenu} row={usr} onAction={actionHandler} />
                                </div>

                            </td>
                        </tr>)}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
                <SidePanel show={showWalletAddress} title="Wallet Address">
                    <WalletAddressDetails row={selectedData} triggerClose={closeWalletPanel} />
                </SidePanel>
                <Popup show={showCreateUserPopup}
                    style={{ 'maxWidth': '51.42rem' }}
                    title={`${selectedData.id ? 'Edit User' : 'Add User'}`} cssClass="user-create-pop" onClose={onCloseCreateUser}>
                    <CreateUser triggerClose={onCloseCreateUser} row={selectedData} />
                </Popup>

                <Popup show={showNewWalletPopup}
                    style={{ 'maxWidth': '51.42rem' }}
                    title={`Add wallet`} cssClass="user-create-pop" onClose={onCloseAddWallet}>
                    <AddWallet triggerClose={onCloseAddWallet} row={selectedData} />
                </Popup>

                <Popup show={showLoyaltyTransactionHistory}
                    style={{ 'maxWidth': '51.42rem' }}
                    title={`Transaction History`} cssClass="user-create-pop" onClose={onCloseLoyaltyTransactionHistory}>
                    <LoyaltyTransactions triggerClose={onCloseLoyaltyTransactionHistory} row={selectedData} />
                </Popup>

                <ConfirmPopup row={selectedData} show={showBlockPopup} content={selectedData.isBlocked ? 'Are you sure want to unblock this user?' : 'Are you sure want to block this user?'} buttonYes={selectedData.isBlocked ? 'Unblock' : 'Block'} buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title={selectedData.isBlocked ? 'Unblock User' : 'Block User'} onClose={onCloseBlockUser}>
                    <div className="flex block-user-card">
                        <User src={selectedData.profilePicUrl} label={selectedData.name} email={selectedData.email} className="wallet-profile" />
                    </div>
                </ConfirmPopup>

                <ConfirmPopup row={selectedData} show={showDeletePopup} content='Are you sure want to delete this user?' buttonYes="Delete" buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title="Delete User" cssClass="delete-user" onClose={onCloseDeleteUser}>
                    <div className="flex block-user-card">
                        <User src={selectedData.profilePicUrl} label={selectedData.name} email={selectedData.email} className="wallet-profile" />
                    </div>
                </ConfirmPopup>
                <ViewDetails show={showViewDetailsPopup} row={selectedData} />
            </div>
        </div>
    )
})
export default UserList;