import { capitalizeFirstLetter } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";

const useCollectionAPIHelper = () => {
  let { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const getCollectionAssets = (data, callback) => {
    triggerAPI(
      {
        url: `user/collection/assets`,
        data,
        method: "post",
      },
      (res) => {
        callback(res?.data);
      }
    );
  };
  const updateFavoriteCommunityAssets = (data,isFav,callback) => {
    triggerAPI({
        url: `community/${isFav?"remove-favorite-assets":"add-assets-to-favorite"}`,data, method: 'post',
      }, res => {
        callback(res?.data)
      },err=>{
        callback(false)
         dispatch('showToast', { toast: { toastMode: 'error', message: capitalizeFirstLetter(err?.response?.data?.error?.message) } })
      });
  }
  const updateHideCommunityAssets = (data, isHidden, callback) => {
    triggerAPI(
      {
        url: `community/${isHidden ? "remove-hided-assets" : "hide-assets"}`,
        data,
        method: "post",
      },
      (res) => {
        callback(res?.data);
      }
    );
  };
  const getActionsAppliedCreatorAssets = (data, callback) => {
    triggerAPI(
      {
        url: `community/creator-assets`,
        data,
        method: "post",
      },
      (res) => {
        callback(res?.data);
      }
    );
  };
  const updateCommunityAssetMarketplaceLink = (data,callback) => {
    triggerAPI({
        url: `community/add-assets-extra-details`,data, method: 'post',
      }, (res => {
        callback(res?.data)
      }),(err=>{
        callback(false)
         dispatch('showToast', { toast: { toastMode: 'error', message: capitalizeFirstLetter(err?.response?.data?.error?.message) } })
      }));
  }
  const updateOrderingOfCommunityFavoriteAssets = (data, callback) => {
    triggerAPI(
      {
        url: `community/update-fav-assets-order`,
        data,
        method: "post",
      },
      (res) => {
        callback(res?.data);
      }
    );
  };
  return {
    getCollectionAssets,
    updateFavoriteCommunityAssets,
    updateHideCommunityAssets,
    getActionsAppliedCreatorAssets,
    updateOrderingOfCommunityFavoriteAssets,
    updateCommunityAssetMarketplaceLink
  };
};
export default useCollectionAPIHelper;
