import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import ActionButton from "Assets/Images/ActionButton.svg";
import Image from "App/Components/UI/Image";
import { useCallback, useEffect, useMemo, useState } from "react";
import tableSort from "App/helpers/tableSort";
import ButtonAction from "App/Components/UI/ButtonAction";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import Status from "App/Components/UI/Status";
import MenuPen from "Assets/Images/menu-pen.svg";
import Delete from "Assets/Images/menu-del.svg";
import filter from "Assets/Images/filter-icon.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import "./CommunitiesList.scss";
import SocialMedia from "App/Components/UI/SocialMedia";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import { LIST_LIMIT } from "App/constants";
import ModalOuter from "App/Components/UI/ModalOuter";
import EditCommunity from "../EditCommunity";
import TableSelectBox from "App/Components/UI/TableSelectBox";
import { categorySort } from "App/helpers/utilities";
import Button from "App/Components/UI/Button";
import { getBlockchainIcon } from "App/helpers/blockchain";
import plus from "Assets/Images/plus.svg";
import EditCollection from "../../../CollectionManagement/Pages/EditCollection";
import AddUserToCommunity from "../../Components/AddUserToCommunity";
import Popup from "App/Components/UI/Popup";
import Upload from "../../../CollectionManagement/Components/Upload";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import MultiFilter from "App/Components/UI/MultiFilter";


const paginationConfig = {
  perPage: LIST_LIMIT,
};

const CommunityList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [initial, setInitial] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const [isCommunityPopupOpened, setIsCommunityPopupOpened] = useState(false);
  const [showActivatePop, setShowActivatePop] = useState({ mode: false });
  const [showDeletePop, setShowDeletePop] = useState({ mode: false });

  const [category, setCategory] = useState([]);
  const [openCollectionPopup, setOpenCollectionPopup] = useState(false)
  const [openAddUserToCommunityPopup, setOpenAddUserToCommunityPopup] = useState(false)
  const typeLists = useMemo(() => [{id:"basic",name:"Basic"},{id:"premium",name:"Premium"}], []);
  const [showBulkUploadPopup, setBulkUploadPopup] = useState({
    mode: false,
  });
  const [showBulkCollectionUploadPopup, setBulkCollectionUploadPopup] = useState({
    mode: false,
  });
  const [filterValue,setFilterValue] = useState({})
  

  const actionMenu = (data)=>{
    let list=[
    {
      label: "Edit",
      action: "edit",
      src: MenuPen,
    },
    {
      label: "Delete",
      action: "delete",
      src: Delete,
    }
  ];
  
  if(data?.status === "false" ||
    data?.status === "blocked"){
    list.push({
      label: "Unblock",
      action: "unblock",
      src: MenuLink,
    })
  }else{
    list.push({
      label: "Block",
      action: "block",
      src: MenuLink,
    }) 
  }

    list.push(    {
      label: "Add Collection",
      action: "add-collection",
      src: plus,
    },)
  
  if(data?.status==="unclaimed"){
    list.push(  {
      label: "Add User",
      action: "add-user",
      src: plus,
    })
  }
  return list
}
  const [communityMenu, setCommunityMenu] = useState([
    {
      label: "Collections",
      action: "collectionId",
      sort: true,
      sortKey: "collectionCount",
      multiSelect: true,
      selected: true,
      type: "displayedFrames",
    },
    {
      label: "Blockchains",
      action: "blockchains",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Social Links",
      action: "sociallink",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Twitter Followers",
      action: "twitterFollowersCount",
      sort: true,
      sortKey: "twitterFollowersCount",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Posts",
      action: "announcements",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    // {
    //   label: "Upcoming Events",
    //   action: "events",
    //   selected: true,
    //   multiSelect: true,
    //   type: "displayedFrames",
    // },
    {
      label: "Pieces",
      action: "pieces",
      sort: true,
      sortKey: "collectionAssetsCount",
      multiSelect: true,
      selected: true,
      type: "displayedFrames",
    },
    {
      label: "Cur8 Collector",
      action: "cur8Collector",
      sort: true,
      sortKey: "assetHoldingUsersCount",
      multiSelect: true,
      selected: true,
      type: "displayedFrames",
    },

    {
      label: "Followers",
      action: "followers",
      sort: true,
      sortKey: "followersCount",
      multiSelect: true,
      selected: true,
      type: "displayedFrames",
    },
    {
      label: "About Us",
      action: "aboutus",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
   
    {
      label: "Blockchain",
      action: "blockchain",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Blockchain IDs",
      action: "blockchainId",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },

    {
      label: "Home Category",
      action:"homeCategory",
      selected: true,
      sort: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Level",
      action:"level",
      selected: true,
      sort: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    // {
    //   label: "Featured",
    //   action: "statusToggle",
    //   sortKey: "showOnHomePageDisplay",
    //   selected: true,
    //   sort: true,
    //   multiSelect: true,
    //   type: "statusToggle",
    // },
    {
      label: "Collector Only",
      action: "isCollectorsOnly",
      sort: true,
      sortKey: "isCollectorsOnly",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Created At",
      action: "createdAt",
      sort: true,
      sortKey: "createdAt",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Created By",
      action: "createdBy",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Admin",
      action: "admin",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
  ]);

  const [sort, setSort] = useState({ direction: "", sortKey: "createdAt" });
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "";
      const direction = tableSort(dir);
      setSort({ direction, sortKey });
    },
    [sort]
  );
  const [list, setList] = useState([]);
  const handleCloseAddUserToCommunityPopup = () => {
    setOpenAddUserToCommunityPopup(false)
    setSelectedData({})
  };

  const handleOpenAddUserToCommunityPopup = () => {
    setOpenAddUserToCommunityPopup(true)
  };
  const handleCloseCollectionPopup = () => {
    setOpenCollectionPopup(false)
    setSelectedData({})
  };

  const handleOpenCollectionPopup = () => {
    setOpenCollectionPopup(true)
  };

  const onPageChangeHandler = (page) => {
    setPage(page);
            const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
  };
  const openCommunityPopup=()=>{
    setIsCommunityPopupOpened(true)
  }
  const closeCommunityPopup=()=>{
    setSelectedData({})
    setIsCommunityPopupOpened(false)
  }
  const actionHandler = (e) => {
    setSelectedData(e.row);
    switch (e?.action) {
      case "edit":
        openCommunityPopup()
      // navigate(`details/${e?.row?.id}`);
      return
      case "add-collection":
        handleOpenCollectionPopup() 
      return
      case "add-user":
        handleOpenAddUserToCommunityPopup() 
      return
      case "delete":
        return setShowDeletePop({ mode: true });
      case "block":
      case "unblock":
        return setShowActivatePop({ mode: true });
      default:
        return;
    }
  };
  const stopHandler = (e) => {
    e.stopPropagation();
  };

  const searchHandler = (e) => {
    setSearch(e);
  };

  const getCollectionListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { list, pageMeta } = res.data;
      setList(list);
      setTotalPage(pageMeta.totalItems);
    },
    [dispatch]
  );

  const getCategoryResponse = useCallback(
    (res) => {
      let updatedList=categorySort(res?.data?.list);
      updatedList=updatedList.map(i=>({...i,subcategories:null}))
      setCategory(updatedList);
      // setDataFetched((prev) => {
      //   return { ...prev, category: true };
      // });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getCategoryResponse, triggerAPI]);

  const getCommunityList = useCallback(() => {
    dispatch("showSpinner");
    let filter = {
      sortBy: sort.sortKey,
      orderBy: sort.direction,
      ...filteredData,
    };
    if(filterValue?.comLevels?.length===1){
        filter={...filter,type:filterValue.comLevels[0]}
    }
    if(filterValue?.comHomeCat?.length){
        filter={...filter,communityCategoryId:filterValue.comHomeCat}
    }

    triggerAPI(
      {
        url: `community/get`,
        data: {
          page: page,
          items: paginationConfig.perPage,
          search: `${search}`,
          filter,
        },
        method: "post",
      },
      getCollectionListResult
    );
  }, [
    dispatch,
    filteredData,
    getCollectionListResult,
    page,
    search,
    sort.direction,
    sort.sortKey,
    triggerAPI,
    filterValue
  ]);

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showActivatePop?.mode) setShowActivatePop({ mode: false });
    if (showDeletePop?.mode) setShowDeletePop({ mode: false });
    if (showBulkUploadPopup?.mode) setBulkUploadPopup({ mode: false });
    if (showBulkCollectionUploadPopup?.mode) setBulkCollectionUploadPopup({ mode: false });



    getCommunityList();
  };

  const downloadExcelFile = async (res) => {
    dispatch("hideSpinner");
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Cur8_Creators_List.xlsx');  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const activateHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/${
          selectedData?.status === "true" || selectedData?.status === "active"
            ? "block"
            : "active"
        }/${selectedData?.id}`,
        data: {},
        method: "patch",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `${
                selectedData?.isBlocked ? "Blocking" : "Unblocking"
              } community failed`,
          },
        });
      }
    );
  };

  const deleteCommunityHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/remove/${selectedData?.id}`,
        data: {},
        method: "post",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `failed`,
          },
        });
      }
    );
  };

  const onFilterData = (e) => {
    setFilteredData(e);
  };

  useEffect(() => {

    if (page !== 1) {
      setPage(1);
    } else {
      getCommunityList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, search, filteredData,filterValue]);

  useEffect(() => {
    getCategoryList();

    if (!initial) {
      getCommunityList();
    }
    setInitial(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch("setPageTitle", "Communities");
  }, [dispatch]);

  const onCloseActivate = (data) => {
    if (data) {
      activateHandler();
    } else {
      setShowActivatePop({ mode: false });
    }
  };
  
  const onCloseDeleteActivate = (data) => {
    if (data) {
      deleteCommunityHandler();
    } else {
      setShowDeletePop({ mode: false });
    }
  };


  const getSelectListBoxResult = (res) => {
    getCommunityList();
    const { message } = res;
    dispatch('hideSpinner')
    dispatch('showToast', { toast: { toastMode: 'success', message } })
  }
  const getSelectTypeListBoxUpdate = (selected, row) => {
    dispatch('showSpinner');
     let data = {
          communityId: row.id,
          type:selected?.type
        }
      triggerAPI({
        url: `admin/update-community`, data, method: 'post'
    }, getSelectListBoxResult);    
  }

  const getSelectListBoxUpdate = (selected, row) => {
      dispatch('showSpinner');
      let data = {};

      if(selected['categoryId']===0){
    
        data = {
            communityId: row.id
          }
        triggerAPI({
          url: `admin/delete-community-category`, data, method: 'post'
      }, getSelectListBoxResult);
      
    }else{
    
      data = {
          communityId: row.id,
          categoryId: selected['categoryId']
        }
        triggerAPI({
          url: `admin/update-community-category`, data, method: 'post'
      }, getSelectListBoxResult,(err) => {
        getCommunityList();
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message
          },
        });
      });
    
    }
      
  }
  
 const bulkUpload = () => {
    setBulkUploadPopup({ mode: true });
  };

  const onCloseBulkUpload = () => {
    setBulkUploadPopup({ mode: false });
  };
  const onCloseBulkCollectionUpload = () => {
    showBulkCollectionUploadPopup({ mode: false });
  };
  const submitBulkUpload = (data) => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/creator/bulk-upload`,
        data,
        method: "post",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message || "Bulk uploading failed",
          },
        });
      }
    );
  };
  const toggleCollectorOnlySwitchResult = (res) => {
    getCommunityList();
    const { message } = res;
    dispatch('hideSpinner')
    dispatch('showToast', { toast: { toastMode: 'success', message } })
  }
  const toggleCollectorOnlySwitch = (id, checked) => {
    dispatch('showSpinner');
    const data = {
      communityId:id,
      isCollectorsOnly:checked
    }
    triggerAPI({
        url: `admin/update-community-status`, data, method: 'post'
    }, toggleCollectorOnlySwitchResult);
}
  const submitBulkCollectionUpload = (data) => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/creator/link-collections`,
        data,
        method: "post",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message || "Bulk uploading failed",
          },
        });
      }
    );
  };


   const selectListBox = (selected,row) => {
    getSelectListBoxUpdate(selected,row);
  }

  const onExport = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/export-creator-details`,
        method: "get",
        responseType:'blob',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      },
      downloadExcelFile,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message || "Error downloading the file",
          },
        });
      }
    );
  };

  const clickHandleCreatedBy = (createdUser) => {
    if(!createdUser) return;
    dispatch('setCreatedByUserName', createdUser);
    navigate('/dashboard/users/list')
  }


  const creatorLink=(slug)=>{
    return `${process.env.REACT_APP_USER_SITE_URL}/@${slug}/overview`
}

  const getGenratethumnailList = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI({
        url: `admin/creator-list/generate-thumbnail`,
        method: "get",
      },(response) => {
        dispatch("hideSpinner");
        const { data } = response;
        if(data?.length) {
          let creatorData = data?.map(item => {
            let blockChains = item?.blockchain?.filter(item => item)
            let collectionFilePath = item?.collectionFilePath256?.filter(item => item)
            return  {
                blockChains: blockChains,
                coverImage: item?.bannerPicture,
                profileImage: item?.displayPicture256,
                collectionList: collectionFilePath,
                name: item?.name,
                id: item?.id,
                type: 'community'
              }
          })
          dispatch('setScreenShotData', creatorData)
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilter = useCallback((ids,data, filter = '')=>{

    if(ids?.length){
      if(filter === 'comLevels') {
        setFilterValue((pre)=>{
          return {...pre, comLevels: ids}
        })
      } 
      if(filter === 'comHomeCat') {
        setFilterValue((pre)=>{
          return {...pre, comHomeCat: ids}
        })
      }
  }else clearFilterHandler()

  },[])

  const clearFilterHandler=()=>{
    setFilterValue((pre)=>({comLevels:[],comHomeCat:[]}))
  }
  return (
  
    <>
    {!!isCommunityPopupOpened && <ModalOuter openModal={true}
        parentClass={isCommunityPopupOpened ? '!z-[10]' : '!hidden !z-[10]'}
        >
        <EditCommunity isCreator type={'community'} onClosePopup={closeCommunityPopup} id={selectedData?.id} onRefetchList={getCommunityList}/>
      </ModalOuter>}
      {openAddUserToCommunityPopup && <ModalOuter openModal={openAddUserToCommunityPopup}
        parentClass={openAddUserToCommunityPopup ? '!z-[10]' : '!hidden !z-[10]'}
        >
        <AddUserToCommunity communityId={selectedData?.id} onRefetchList={getCommunityList} onClosePopup={handleCloseAddUserToCommunityPopup} />
      </ModalOuter>}
      {openCollectionPopup && <ModalOuter openModal={openCollectionPopup}
        parentClass={openCollectionPopup ? '!z-[10]' : '!hidden !z-[10]'}
        >
        <EditCollection community={selectedData} communityId={selectedData?.id} isVerifyCollection onRefetchList={getCommunityList} onClosePopup={handleCloseCollectionPopup} />
      </ModalOuter>}
      <div className="communities">
      <div className="flex justify-between items-center mb-4">

        <div className="search-filter flex gap-[1rem] items-center">
          <div className="search-filter">
            <SearchFilter
              position={{ right: 150, top: 10 }}
              onSearch={searchHandler}
              data={filteredData}
              setActionMenu={setCommunityMenu}
              actionMenu={communityMenu}
              onFilterData={onFilterData}
              apply={true}
              limit={4}
            />
          </div>
          <MultiFilter 
                  image={filter} 
                  communityCategory={category} 
                  // options={categoryOptions} 
                  onSelected={handleFilter}
                  categoryFilterActive={false}
                  communityHomeCatFilter={true}
                  communityLevelFilter={true}
                  className={'multi_filter-box-style'}
               />
          </div>

          <div className="flex gap-2 items-center">
              {/* <Button label="Import Collections" onClick={bulkCollectionUpload}/> */}
              <Button label="Add" onClick={openCommunityPopup}/>
              <Button label="Import" onClick={bulkUpload}/>
              <Button label="Export" onClick={onExport}/>
              <Button label="Genrate thumbnails" onClick={getGenratethumnailList}/>
            </div>
        </div>
        
           
        <div>
          <Table>
            <thead className="text-left">
              <tr>
                <th>
                  <TableHeader
                    label="Community"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="name"
                  />
                </th>

                {communityMenu
                  ?.filter((el) => el?.selected)
                  ?.map((val,i) => (
                    <th key={`${val?.action}${i+1}`}>
                      <TableHeader
                        label={val?.label}
                        sort={sort}
                        onSort={(key) => val?.sort && onSortHandler(key)}
                        sortKey={val?.sortKey ? val?.sortKey : ""}
                      />
                    </th>
                  ))}
                <th>
                  <TableHeader
                    label="Status"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="status"
                  />
                </th>
                <th>
                  <div className="flex justify-end items-center">
                    <TableHeader label="Actions" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="gallery-body">
              {list?.map((value) => {
                let imagePreview = value?.displayPicture;
                return (
                  <tr data-galleryid={value?.id} key={value.id}>
                    <td className="flex gap-3 items-center ">
                      <div className="wall-image-gallery">
                        <Image
                          src={imagePreview ? imagePreview : ""}
                          className="gallery-image"
                          loaderClass="nft-drop-loader"
                          style={{ maxWidth: "4rem", Height: "4rem" }}
                        />
                      </div>
                    <span className="name-span">{value?.name}</span> 
                     <div className="anchor-wrapper">
                                        <a onClick={(e)=>e.stopPropagation()} className="link-img-wrapper"   target="_blank" 
                                            rel="noreferrer"  href={creatorLink(value?.slug)}  >
                                            <img src={MenuLink} alt=""/>
                                        </a>
                                        </div>
                    </td>
                    {!!communityMenu?.find(
                      (val) => val?.label === "Collections"
                    )?.selected && <td>{value?.collectionCount || value?.communityCollections?.length}</td>}
                       {!!communityMenu?.find((val) => val?.label === "Blockchains")
                      ?.selected && (
                      <td>
                        
                        <div className="flex w-max">
                        {value?.blockchain?.map((code,i)=><img key={i+1} src={getBlockchainIcon(code)} className="w-[1.4rem]" alt=""/>)}
                        </div>
                        </td>
                    )}

                    {!!communityMenu?.find(
                      (val) => val?.label === "Social Links"
                    )?.selected && (
                      <td>
                        <div className="flex gap-2">
                          {!!value?.website && (
                            <SocialMedia link={value?.website} type="website" />
                          )}
                          {!!value?.twitter && (
                            <SocialMedia link={value?.twitter} type="twitter" />
                          )}
                          {!!value?.discord && (
                            <SocialMedia link={value?.discord} type="discord" />
                          )}
                          {!!value?.instagram && (
                            <SocialMedia
                              link={value?.instagram}
                              type="instagram"
                            />
                          )}
                          {!!value?.youtube && (
                            <SocialMedia link={value?.youtube} type="youtube" />
                          )}
                        </div>
                      </td>
                    )}

                    {!!communityMenu?.find(
                      (val) => val?.label === "Twitter Followers"
                    )?.selected && <td>{value?.twitterFollowersCount??''}</td>}

                    {!!communityMenu?.find(
                      (val) => val?.label === "Posts"
                    )?.selected && <td>{value?.announcementsCount}</td>}
                    {/* {!!communityMenu?.find((val) => val?.label === "Upcoming Events")
                      ?.selected && <td>{value?.upcomingEventsCount}</td>}    */}
                    
                        {!!communityMenu?.find(
                      (val) => val?.label === "Pieces"
                    )?.selected && <td>{value?.collectionAssetsCount}</td>}
                      {!!communityMenu?.find(
                      (val) => val?.label === "Cur8 Collector"
                    )?.selected && <td>{value?.assetHoldingUsersCount}</td>}
                      {!!communityMenu?.find(
                      (val) => val?.label === "Followers"
                    )?.selected && <td>{value?.followersCount}</td>}

                    {!!communityMenu?.find((val) => val?.label === "About Us")
                      ?.selected && <td>{value?.about}</td>}

                      {!!communityMenu?.find((val) => val?.label === "Home Category")
                      ?.selected && <td>
                         
                        <TableSelectBox
                          list={value?.communityCategoryId ? [...category,{id:0,name:'Clear'}]:category}
                          placeHolder="Select"
                          label={``}
                          className="frame-form"
                          onChange={selectListBox}
                          keyName={"id"}
                          controlName="categoryId"
                          row={value}
                          currentId={value?.communityCategoryId}
                          tabIndex={0}
                        />
                        </td>}
                        {!!communityMenu?.find((val) => val?.label === "Level")
                      ?.selected && <td>     
                        <TableSelectBox
                          list={typeLists}
                          placeHolder="Select"
                          label={``}
                          className="frame-form min-w-[7rem]"
                          onChange={getSelectTypeListBoxUpdate}
                          keyName={"id"}
                          valueType="string"
                          controlName="type"
                          row={value}
                          currentId={value?.type}
                          tabIndex={0}
                        />
                        </td>}

             
                    {!!communityMenu?.find((val) => val?.label === "Blockchain")
                      ?.selected && (
                      <td>{value?.fungibleTokens[0]?.blockchainType?.name}</td>
                    )}
                    {!!communityMenu?.find(
                      (val) => val?.label === "Blockchain IDs"
                    )?.selected && (
                      <td>{value?.fungibleTokens[0]?.blockchainType?.id}</td>
                    )}
                    {/* {!!communityMenu?.find(
                      (val) => val?.label === "Featured"
                    )?.selected && <td>
                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                            {
                            // value?.status === 'active' && 
                            <ToggleSwitch className="gallery-switch" check={value?.showOnHomePageDisplay} row={value} onChange={toggleSwitch} />}
                        </div>
                      </td>} */}
                                  {!!communityMenu?.find(
                      (val) => val?.label === "Collector Only"
                    )?.selected && <td>
                        <div className="flex justify-center items-center" onClick={stopHandler}>
                            {
                            // value?.status === 'active' && 
                            <ToggleSwitch className="gallery-switch" check={value?.isCollectorsOnly} row={value} onChange={toggleCollectorOnlySwitch} />}
                        </div>
                      </td>} 
                    {!!communityMenu?.find(
                      (val) => val?.label === "Created At"
                    )?.selected && (
                      <td>{new Date(value?.createdAt)?.toLocaleString()}</td>
                    )}
                    {!!communityMenu?.find(
                      (val) => val?.label === "Created By"
                    )?.selected && (
                      <td className="cursor-pointer" onClick={()=>{clickHandleCreatedBy(value?.user?.username)}}>{value?.user?.username}</td>
                    )}
                     {!!communityMenu?.find((val) => val?.label === "Admin")
                      ?.selected && <td>{value?.createdAdmin||""}</td>}
                    <td>
                      <Status
                        label={
                          value?.status === "false" ||
                          value?.status === "blocked"
                            ? "Blocked"
                            :value?.status === "unclaimed"?"Un Claimed": "Active"
                        }
                      />
                    </td>
                    <td>
                      <div
                        className="flex justify-end items-center"
                        onClick={stopHandler}
                      >
                        <ButtonAction
                          row={value}
                          src={ActionButton}
                          menu={actionMenu(value)}
                          onAction={actionHandler}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!list?.length && (
            <div className="text-center no-rows pt-4">
              <p>No communities found!</p>
            </div>
          )}
          <div className="mt-6">
            <Pagination
              showPageInput={true}
              itemsPerPage={paginationConfig.perPage}
              total={totalPage}
              rows={list?.length}
              onPageChange={onPageChangeHandler}
              selected={page}
            />
          </div>
        </div>
      </div>
      <ConfirmPopup
        row={selectedData}
        show={showActivatePop}
        content={`Are you sure want to ${
          selectedData?.status === "false" || selectedData?.status === "blocked"
            ? "unblock"
            : "block"
        } this community?`}
        buttonYes="Yes"
        buttonCancel="No"
        style={{ maxWidth: "30rem" }}
        title={`${
          selectedData?.status === "false" || selectedData?.status === "blocked"
            ? "Unblock"
            : "Block"
        } community`}
        cssClass="delete-user"
        onClose={onCloseActivate}
      ></ConfirmPopup>
       <ConfirmPopup
        row={selectedData}
        show={showDeletePop}
        content={`Are you sure want to delete this community?`}
        buttonYes="Yes"
        buttonCancel="No"
        style={{ maxWidth: "30rem" }}
        title={`Delete community`}
        cssClass="delete-user"
        onClose={onCloseDeleteActivate}
      ></ConfirmPopup>
         <Popup
        show={showBulkUploadPopup}
        style={{ maxWidth: "40rem" }}
        title={`Import`}
        cssClass="user-create-pop"
        onClose={onCloseBulkUpload}
      >
        <Upload triggerClose={onCloseBulkUpload} submit={submitBulkUpload} />
      </Popup>
      <Popup
        show={showBulkCollectionUploadPopup}
        style={{ maxWidth: "40rem" }}
        title={`Upload and link collections`}
        cssClass="user-create-pop"
        onClose={onCloseBulkUpload}
      >
        <Upload triggerClose={onCloseBulkCollectionUpload} submit={submitBulkCollectionUpload} />
      </Popup>
    </>
  );
};

export default CommunityList;
