
import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet,  } from "react-router-dom";


const Reports = () => {
    const dispatch = useStore(false)[1];
    useEffect(() => {
        dispatch('setPageTitle', 'Reports')
    }, [dispatch])

    return (
        <div>
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    )
}
export default Reports;