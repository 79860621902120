import useHttp from "App/hooks/use-http";

const useReportHelper = () => {
  let { triggerAPI } = useHttp();
  const getCommunityReportDataAPI = (data, callback) => {
    let params = new URLSearchParams(data);
    triggerAPI(
      {
        url: `community-report/get?${params}`,
        method: "get",
      },
      (res) => {
        callback(res);
      }
    );
  };

  return {
    getCommunityReportDataAPI
  };
};
export default useReportHelper;
