import { useCallback, useEffect, useMemo, useState } from "react";
import "./UpdateMarketplacePopup.scss";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import { objectToNull } from "App/helpers/utilities";
import Popup from "App/Components/UI/Popup";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import Button from "App/Components/UI/Button";
import ModalOuter from "App/Components/UI/ModalOuter";

const UpdateMarketplacePopup = ({
  onClose,
  updateMarketplaceHandler = () => {},
  data,
}) => {
  let initialFormValues = useMemo(() => {
    let formData = {
      marketplaceLink: {
        value: undefined,
        isRequired: true,
      },
    };
    return formData;
  }, []);

  const [formGroup, setFormGroup] = useState(initialFormValues);
  const [formValues, setFormValues] = useState(getInitial(formGroup));
  const [touched, setTouched] = useState(false);
  const [formValidState, setFormValidState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const dispatch = useStore()[1];
  const marketplaceLinkValidator = useMemo(
    () => [
      {
        type: "Required",
        message: "Required",
      },
      { type: "url", message: `Invalid URL` },
    ],
    []
  );
  useEffect(() => {
    return () => {
      setFormGroup(initialFormValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data?.assetMarketplaceLink) {
      setFormValues((prev) => ({
        ...prev,
        marketplaceLink: data?.assetMarketplaceLink ?? "",
      }));
    }
  }, [data?.assetMarketplaceLink]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formValidState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formValidState, formGroup]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);
  const inputOnChange = (e) => {
    setFormValues({ ...formValues, ...e });
  };
  const collectErrors = useCallback((error) => {
    setFormValidState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const onSave = () => {
    if (isValid) {
      dispatch("showSpinner");
      let updatedFormValues = { ...formValues };
      updatedFormValues = objectToNull(updatedFormValues);
      updateMarketplaceHandler(updatedFormValues, () => {
        dispatch("hideSpinner");
        onClose();
        dispatch("showToast", {
          toast: {
            toastMode: "success",
            message: "Updated marketplace link successfully",
          },
        });
      });
    } else {
      setTouched(true);
    }
  };
  return (
    <ModalOuter openModal={true} parentClass={"!z-[10] update-marketplace-link-popup"}>
     <div className="modal-wrapper-content">
     <h1 className="popup-title text-center">{"Update Marketplace Link"}</h1>
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-6 form-wrapper">
          <div className="">
            <InputBox
              type="text"
              label="Marketplace Link"
              validateOnBlur
              placeholder="Marketplace link"
              onChange={inputOnChange}
              controlName="marketplaceLink"
              value={formValues?.marketplaceLink || ""}
            />
            <Validators
              value={formValues.marketplaceLink}
              validators={marketplaceLinkValidator}
              controlName="marketplaceLink"
              onErrors={collectErrors}
            />
          </div>
          <div className="w-full flex justify-center gap-2">
            <Button
              className={"cancel-btn flex-1 btn-style"}
              onClick={onClose}
              Type={1}
              label={"Cancel"}
            ></Button>
            <Button
              className={`save-btn btn-style save flex-1`}
              onClick={onSave}
              Type={1}
              label={"Update"}
            ></Button>
          </div>
        </div>
      </div>
     </div>
    </ModalOuter>
  );
};

export default UpdateMarketplacePopup;
