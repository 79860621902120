import "./AssetListing.scss";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import AssetCard from "../AssetCard";
import useCollectionLoader from "App/hooks/use-collection-data-handler";
import { useStore } from "App/hooks-store/store";
import { useDebounce } from "App/hooks/use-debounce";
import { useNavigate } from "react-router-dom";
import useDragAndDrop from "App/hooks/use-drag-and-drop";
import SkeletonLoader from "App/Components/UI/SkeletonLoader";
import { copyClipHandler, loadArraySegment, sequenceUpdate, UTF8ToHex } from "App/helpers/utilities";
import SearchBar from "App/Components/UI/SearchFilter/Components/SearchBar";
import useInfiniteScroll from "App/hooks/use-infinite-scroll";
import PieceDetailsView from "../../../EditCollection/SubComponents/PieceDetailsView";
import { getBlockchainIcon } from "App/helpers/blockchain";
import copyIcon from "Assets/Icons/copy_icon.svg";

const AssetListing = ({
  collectionId,
  assetData = null,
  OrderingOfAssetsHandler = () => {},
  tiltAnimation = true,
  draggable = false,
  collectionDetails,
  showSearch,
  favoriteMaxLimit = null,
  assetListData = {},
  showCaption,
  addMoreHandler,
  filterWhileDoingAnAction,
  favoriteHandler = () => {},
  hideHandler = () => {},
  showData = {},
  onEditAsset = () => {},
  assetIds = null,
  loaderCount,
  displayType = "sm",
  onClick = null,
  scrollId = "",
  isOwnCreator = false,
  showApplyFrameButton = true,
  initialLoading = true,
  totalItems = 1,
  loadItemsPerPage = 30,
  className = "",
  isMarketplace = false,
  isGallery = false,
  isManage = false,
  totalFavoriteAssetsCount=0,
  returnAssetList = null,
  activeAssetList = [],
  getImageAspectRatio = () => {},
  onDeleteAsset = () => {},
  isMedia = false,
  actionsWithText,
  communityId,
  updateAssetMarketplaceLinkHandler=()=>{},
  setAsThumbnailHandler=()=>{}
}) => {
  const { getAssetList } = useCollectionLoader();
  const [store,dispatch] = useStore();
  const navigate = useNavigate();
  const { currentSelectedNfts } = store;
  const [viewType, setViewType] = useState(displayType);
  const [assetList, setAssetList] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [lastFetched, setLastFetched] = useState(0);
  const [disableActions, setDisableActions] = useState(false);
  const [totalFavAssets, setTotalFavAssets] = useState(0);
  const [fetchingProgress, setFetchingProgress] = useState(false);
  const [searchAssetValue, setSearchAssetValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [searchedAssetIdsLists, setSearchedAssetIdsLists] = useState(null); // to handle search of this component

  const innerSearchValue = useDebounce(searchAssetValue || "", 500);
  const onDrop = (fromIndex, toIndex, draggedData) => {
    if(draggable){
      let orderUpdatedAssets = sequenceUpdate(assetList, fromIndex, toIndex);
      setAssetList(orderUpdatedAssets);
      OrderingOfAssetsHandler(toIndex, draggedData);
    }
  };
  const {
    handleDragLeave,
    handleDrop,
    handleDragEntered,
    handleDragStart,
    draggedOverContainerId,
  } = useDragAndDrop(onDrop, assetList);
  let resetData = () => {
    setPageNo(1);
    setLastFetched(0);
    setAssetList([]);
  };
  useEffect(() => {
    if (showSearch) {
      let searchResult = innerSearchValue?.trim();
      if (searchResult) {
        resetData();
        let searchAssetListPayload = {};
        if (collectionDetails?.blockchainType?.code === "cardano") {
          let convertedAssetName = UTF8ToHex(searchResult);
          searchAssetListPayload = {
            ...searchAssetListPayload,
            asset_name: convertedAssetName,
          };
        } else if (
          collectionDetails?.blockchainType?.code === "solana" ||
          collectionDetails?.blockchainType?.code === "bitcoin"
        ) {
          searchAssetListPayload = {
            ...searchAssetListPayload,
            chain: collectionDetails?.blockchainType?.code,
            asset_name: "0",
            policy_id: collectionDetails?.policy_id,
            contractAddress: searchResult,
          };
        } else {
          searchAssetListPayload = {
            ...searchAssetListPayload,
            chain: collectionDetails?.blockchainType?.code,
            asset_name: searchResult,
            policy_id: collectionDetails?.contractAddress,
            contractAddress: collectionDetails?.contractAddress,
          };
        }
        setSearchedAssetIdsLists([searchAssetListPayload]);
      } else {
        resetData();
        setSearchedAssetIdsLists(assetIds);
      }
    } else {
      resetData();
      setSearchedAssetIdsLists(assetIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch, collectionDetails, innerSearchValue, assetIds]);

  const fetchMoreData = () => {
    if (pageNo * loadItemsPerPage >= totalItems) {
      setIsFetching(false);
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreData, {
    elementId: !!scrollId ? scrollId : "asset-listing",
  });
  const showLoading = useMemo(() => {
    return initialLoading || isFetching;
  }, [initialLoading, isFetching]);

  const dataToFech = useMemo(() => {
    let assetLists = searchedAssetIdsLists?.length
      ? searchedAssetIdsLists
      : assetIds;
    let apiCall = !assetData?.length && !!assetLists?.length,
      list = !!assetData?.length ? [...assetData] : [];
    return { apiCall, list: apiCall ? assetLists : list };
  }, [assetIds, assetData, searchedAssetIdsLists]);

  const getNextPageData = useCallback(
    ({ assetList = [], apiCall = false }) => {
      const newAssetList = loadArraySegment({
        list: [...assetList],
        pageNo: pageNo,
        itemsPerPage: loadItemsPerPage,
      });
      setLastFetched(pageNo);
      if (!!newAssetList.length) {
        if (apiCall) {
          getAssetList({
            assetIds: [...newAssetList],
            isMarketplace,
            collectionId,
            callBack: (list) => {
              setAssetList((prev) => {
                return pageNo > 1 ? [...prev, ...list] : list;
              });
              setIsFetching(false);
              setFetchingProgress(false);
            },
          });
        } else {
          setAssetList((prev) => {
            return pageNo > 1 ? [...prev, ...newAssetList] : newAssetList;
          });
          setIsFetching(false);
          setFetchingProgress(false);
        }
      } else {
        if (pageNo === 1) setAssetList([]);
        setIsFetching(false);
        setFetchingProgress(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNo,collectionId,isMarketplace, loadItemsPerPage, assetData]
  );

  useEffect(() => {
    if (!initialLoading && pageNo > lastFetched && !fetchingProgress) {
      setIsFetching(true);
      setFetchingProgress(true);
      getNextPageData({
        assetList: [...dataToFech?.list],
        apiCall: dataToFech?.apiCall,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNo,
    dataToFech,
    loadItemsPerPage,
    setIsFetching,
    lastFetched,
    fetchingProgress,
    initialLoading,
  ]);
  useEffect(() => {
    if(favoriteMaxLimit!==null){
        setTotalFavAssets(totalFavoriteAssetsCount)
    }else{
        setTotalFavAssets(0)   
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [favoriteMaxLimit,totalFavoriteAssetsCount])
useEffect(() => {
  returnAssetList && returnAssetList(assetList);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [assetList])

  const closeAssetHandler = () => {
    setSelectedAsset(null);
  };
  const copytoClip = (e) => {
    const { id } = e.currentTarget.dataset;
    copyClipHandler(id, (res) => {
      res &&
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
    });
  };
  const onAssetClick = useCallback(
    (asset, gallery = false) => {
      if (!gallery) {
        const collectionId = <div className="">
        {!!collectionDetails?.collectionId && (
          <div className="content flex items-center gap-[0.6rem] !text-[1rem]">
            {!!collectionDetails?.blockchainType && <img
              className="w-[1.3rem]"
              alt="Img"
              src={getBlockchainIcon(collectionDetails?.blockchainType?.code)}
            />}
            <p className="mt-1">
              {collectionDetails?.collectionId?.length < 11
                ? collectionDetails?.collectionId
                : collectionDetails?.collectionId?.slice(0, 4) +
                "..." +
                collectionDetails?.collectionId?.slice(-4)}
            </p>
            <img
              onClick={copytoClip}
              data-id={collectionDetails?.collectionId}
              className="cursor-pointer w-4"
              alt="Img"
              src={copyIcon}
            />
          </div>
        )}
      </div>
      setSelectedAsset({piece:asset, community: collectionDetails?.community || null, collectionId});
      }
      else {
        //currentSelectedNfts updated in dependency for ensure getting already values in gallery
        onClick && onClick(asset?.asset, assetList);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetList, currentSelectedNfts]
  );
  useEffect(() => {
    if (assetData?.length) {
      setAssetList(assetData);
    } else {
      setAssetList([]);
    }
  }, [assetData]);
  const updateMarketplaceLinkHandler=(assetDetails,callback)=>{
    let data={
        policy_id:assetDetails?.collectionCollectionId,
        asset:assetDetails?.asset,
        communityId:communityId,
        marketplaceLink:assetDetails?.marketplaceLink
    
    }
    setDisableActions(true)
    updateAssetMarketplaceLinkHandler(data,(res)=>{
        setDisableActions(false)
        if(res){
            let updatedAssetList = assetList?.map((el)=>{
                if(assetDetails?.id===el?.id){
                  return  {...el,assetMarketplaceLink:assetDetails?.marketplaceLink}
                }
                return el
              });
              setAssetList(updatedAssetList);
        }
        callback(true);
    })
}
  const favoriteAssetsHandler = (e, assetDetails, callback) => {
    let data = {
      policy_id: assetDetails?.collectionCollectionId,
      asset: assetDetails?.asset,
      asset_name: assetDetails?.asset_name,
      chain: assetDetails?.blockchainType?.code,
    };
    setDisableActions(true);
    favoriteHandler({data,isFavorite:assetDetails?.isFavoriteCollectionAsset},(res)=>{
      setDisableActions(false)
      if(res){
          let updatedAssetList = assetList?.map((el)=>{
              if(assetDetails?.id===el?.id){
                return  {...el,isFavoriteCollectionAsset:!assetDetails?.isFavoriteCollectionAsset}
              }
              return el
            });
            if(filterWhileDoingAnAction){
              updatedAssetList = updatedAssetList?.filter(list=>list?.isFavoriteCollectionAsset)
          }
            setAssetList(updatedAssetList);
      }
      callback(true);
  })
  };
  const hideAssetsHandler = (e, assetDetails, callback) => {
    setDisableActions(true);
    let data = {
      policy_id: assetDetails?.collectionCollectionId,
      asset: assetDetails?.asset,
      asset_name: assetDetails?.asset_name,
      chain: assetDetails?.blockchainType?.code,
    };
    hideHandler(
      { data, isHidden: assetDetails?.isHidedCollectionAsset },
      (res) => {
        setDisableActions(false);
        let updatedAssetList = assetList?.map((el) => {
          if (assetDetails?.id === el?.id) {
            return {
              ...el,
              isHidedCollectionAsset: !assetDetails?.isHidedCollectionAsset,
            };
          }
          return el;
        });
        if (filterWhileDoingAnAction) {
          updatedAssetList = updatedAssetList?.filter(
            (list) => list?.isHidedCollectionAsset
          );
        }
        setAssetList(updatedAssetList);
        callback(true);
      }
    );
  };
  const onSearchHandler = (searchParam) => {
    setSearchAssetValue(searchParam);
  };

  const filteredAssetLists=useMemo(()=>{
return assetList?.filter((asset) => asset?.onchain_metadata?.name || asset?.name)
  },[assetList])
  return (
  
    <>
      <div
        className={`h-full min-h-[10rem] pieces-total-data-list flex flex-col gap-2 ${
          viewType === "list" && "hidden"
        }`}
        id="asset-listing"
      >
        {!!showSearch && (
          <div className="pr-4">
            <SearchBar
              onSearch={onSearchHandler}
              value={searchAssetValue}
              placeholder={`Search by ${
                collectionDetails?.blockchainType?.code === "cardano"
                  ? "name"
                  : collectionDetails?.blockchainType?.code === "solana" ||
                    collectionDetails?.blockchainType?.code === "bitcoin"
                  ? "contract address"
                  : "token id"
              }`}
            />
          </div>
        )}
        {!!assetListData?.description && (
          <p className="assets-type-text mb-2 flex items-center">*{assetListData?.description}</p>
        )}
        <div
          className={`asset-listing-container relative ${className} ${assetListData?.className} ${
            isMedia && "media-assets-list"
          } ${
            ((!showLoading && !filteredAssetLists?.length 
             // && !addMoreHandler
            ) ||
              viewType === "list") &&
            "hidden"
          }`}
        >
          {
            filteredAssetLists?.map((asset, index) => {
              const active = activeAssetList?.some(
                (item) => item?.asset === asset?.asset
              );
              return (
                <div
                  onDrop={handleDrop}
                  onDragStart={() => handleDragStart(asset.asset)}
                  onDragEnter={() => handleDragEntered(asset.asset)}
                  onDragOver={(e) => e.preventDefault()}
                  key={asset.asset||(index+1)}
                >
                  <AssetCard
                    draggable={draggable}
                    showData={showData}
                    isGallery={isGallery}
                    key={`${asset?.asset}-${index}`}
                    asset={asset}
                    contractAddress={collectionDetails?.contractAddress}
                    disableActions={disableActions}
                    disableFavorite={
                      totalFavAssets === favoriteMaxLimit || disableActions
                    }
                    updateMarketplaceLinkHandler={updateMarketplaceLinkHandler}
                    actionsWithText={actionsWithText}
                    setAsThumbnailHandler={setAsThumbnailHandler}
                    favoriteHandler={favoriteAssetsHandler}
                    hideHandler={hideAssetsHandler}
                    showPricing={isMarketplace || showData?.showPricing}
                    showCaption={showCaption}
                    selectedAsset={active}
                    onAssetClick={onAssetClick}
                    getImageAspectRatio={getImageAspectRatio}
                    tiltAnimation={tiltAnimation ? !active : false}
                    isOwnCreator={isOwnCreator}
                    onDeleteAsset={onDeleteAsset}
                    onEditAsset={onEditAsset}
                    isMedia={isMedia}
                    draggableClassName={`${
                      draggable &&
                      asset?.asset === draggedOverContainerId &&
                      "drag-start"
                    } ${draggable && "drag-n-drop"} relative`}
                  />
                </div>
              );
            })}
          {showLoading &&
            Array(loaderCount || loadItemsPerPage)
              .fill()
              .map((_, index) => (
                <div key={index+1} className={``}>
                  <SkeletonLoader className={`aspect-square`} count={1} />
                </div>
              ))}
        </div>
        {!showLoading && !filteredAssetLists?.length && 
        // !addMoreHandler &&
         (
        <div className="h-full w-full flex items-center justify-center visibleSlowly no-data height-unset">
          <p>No items found</p>
        </div>
      )}
      </div>

      {selectedAsset && (
        <PieceDetailsView openPopup={true} data={selectedAsset} onClosePopup={closeAssetHandler}/>
      )}
    </>
  );
};
export default memo(AssetListing);
