const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCreatedByUserName: (current, createdByUserName) => {
            return {
                createdByUserName
            }
        },
        setCollectorWallet: (current, collectorWallet) => {
            return {
                collectorWallet
            }
        }
    }
    initStore(actions, {
        createdByUserName: '',
        collectorWallet: '',
    });
}
export default configureStore;